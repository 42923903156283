@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  max-width: 1100px;
  width: 100%;
  padding-top: 32px;
  margin: 0 auto;

  .ActivationsContainer {
    margin-top: 12px;
    background-color: var(--white);
    padding: 24px 0;
    border-radius: 8px;

    .Header {
      display: grid;
      grid-template-columns: 190px 32px 78px 32px 46px 32px 90px 32px 200px 32px 276px;
      grid-template-rows: auto;
      grid-template-areas:
      "User . Activated . Gender . Phone .  Email . UserID";
      align-items: center;
      @include text-14-reg;
      color: var(--text-01);
      margin-bottom: 16px;
      padding-left: 24px;
      padding-right: 24px;

      .User {
        grid-area: User;
      }

      .Activated {
        grid-area: Activated;
      }

      .Gender {
        grid-area: Gender;
      }

      .Phone {
        grid-area: Phone;
      }

      .Email {
        grid-area: Email;
      }

      .UserID {
        grid-area: UserID;
      }
    }

    .ActivationItem {
      display: grid;
      grid-template-columns: 36px 8px 146px 32px 78px 32px 46px 32px 90px 32px 200px 32px 276px;
      grid-template-rows: auto;
      grid-template-areas:
      " Logo . User . Activated . Gender . Phone .  Email . UserID";
      align-items: center;
      @include text-14-reg;
      color: var(--text-01);
      border-top: 1px solid var(--selection);
      padding: 16px 24px;

      .Logo {
        grid-area: Logo;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
      }

      .User {
        grid-area: User;
      }

      .Activated {
        grid-area: Activated;
      }

      .Gender {
        grid-area: Gender;
      }

      .Phone {
        grid-area: Phone;
      }

      .Email {
        grid-area: Email;
      }

      .UserID {
        grid-area: UserID;
      }
    }
  }
}