.Container {
  position: relative;
  margin-bottom: 24px;

  :global {
    .InputContainer {
      position: relative;

      .Input {
        display: block;
        width: 100%;
        height: 48px;
        padding: 14px 40px 14px 16px;
        background-color: #F5F7FA;
        border-radius: 12px;
        border: none;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #C1C1C6;
        }
      }

      svg {
        position: absolute;
        right: 14px;
        top: 13px;
        cursor: pointer;

        path {
          fill: #303141;
        }

        &:hover{
          rect {
            fill:#EF3340;
            cursor: pointer;
          }
        }
      }

    }

    .MapContainer {
      position: absolute;
      height: 400px;
      width: 100%;
      left: 0;
      top: 48px;
      background: #FFFFFF;
      box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
      border-radius: 4px 4px 12px 12px;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 5;

    }
  }

}
