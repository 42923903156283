@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  width: 173px;
  height: 173px;
  border-radius: 8px;

  &:active {
    outline: none;
    border: 2px solid #000;
  }

  @include text-14-reg;
  cursor: pointer;

  &:hover {
    .ClearIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ClearIconContainer {
    position: absolute;
    right: -8px;
    top: -8px;
    display: none;
    width: 24px;
    height: 24px;
    background-color: var(--white);
    border-radius: 50%;
    z-index: 5;
    cursor: pointer;

    .ClearIcon rect {
      fill: var(--support-error)
    }
  }

  .PlaceholderContainer {
    background-color: var(--dropzone-bg-color);
    height: 100%;
    border-radius: inherit;

    &.DropzoneActive {
      background-color: var(--dropzone-hover-bg-color);
      border: var(--dropzone-hover-border);
    }
  }

  .Media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
    filter: drop-shadow(0px 14px 14px rgba(67, 72, 152, 0.16));
  }
}

.Border {
  border: var(--dropzone-border);
}