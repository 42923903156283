@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 16px;

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(2, 1fr);
        gap: 9px;
    }

    .Dropzone {
        @include media-breakpoint-down(sm) {
            width: 100% !important;
            height: 150px !important;
        }
    }
}