@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  width: 100%;
  max-width: 912px;
  padding: 16px 30px 4px 24px;
  background: var(--white);
  border-radius: 12px;
  cursor: pointer;

  & + & {
    margin-top: 20px;
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .Tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include text-14-reg;
      color: var(--new);
    }

    .Info {
      display: flex;
      align-items: center;
      min-width: 55px;
      @include text-12-reg;
      color: var(--text-03);

      .KebabIcon {
        margin-left: 10px;
        height: 14px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }


  .Title {
    display: inline-block;
    @include text-20-medium;
    font-weight: 500;
    color: var(--text-01);
    margin-bottom: 12px;
  }

  .Answers {
    display: flex;
    flex-wrap: wrap;
  }
}
