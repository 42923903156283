.Container{
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 8px 0 16px;
  width: 100%;
  min-height: 48px;
  background: #F5F7FA;
  border-radius: 12px;

  .InputContainer{
    position: relative;
    flex-grow: 1;
    margin-bottom: 8px;

    .OptionsContainer {
      position: absolute;
      width: 166px;
      max-height: 240px;
      left: 0;
      top: 40px;
      background: #FFFFFF;
      box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
      border-radius: 4px;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 5;

      :hover {
        background-color: #F0F1FB;
        cursor: url(../../../../shared/icons/cursor.svg) 0 0, pointer;
      }

      .Option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        width: 166px;
        height: 48px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;

        .OptionTitle {
          color: #303141;
        }

        .OptionAmountOfPolls {
          color: #C1C1C6;

        }
      }
    }

    input{
      width: 100%;
      padding-top: 6px;
      padding-bottom: 6px;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #303141;
      cursor: pointer;
      &::placeholder{
        color: #C1C1C6;
      }
    }
  }

  .Tag{
    width: 100px;
    background-color: red;
    margin-right: 20px;
  }

  .Length{
    position: absolute;
    right: 8px;
    bottom: 16px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #C1C1C6;
  }
}
