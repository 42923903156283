@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.DateTimePickerContainer {
  width: fit-content;
  background-color: var(--white);
  z-index: 5;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  border-radius: 12px;

  :global {
    .react-datepicker {
      box-shadow: none;
    }
  }
}

.DateTimePickerInput {
  width: 100%;
  padding: 14px 16px;
  background-color: var(--white);
  border: none;
  border-radius: var(--input-border-radius);
  outline: none;
  @include text-14-reg;
  caret-color: transparent;
  cursor: pointer;
}

.TimePickerContainer {
  display: flex;
  align-items: center;
  padding: 16px;

  .TimePicker {
    width: 100%;
  }
}

.Label {
  margin-right: 16px;
  @include text-14-reg;
  color: var(--text-01);
}