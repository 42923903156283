@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  grid-template-rows: auto;
  align-items: center;
  @include text-14-reg;
  color: var(--text-01);
  padding: 16px 24px;
  border-top: 1px solid var(--selection);
}