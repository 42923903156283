.SearchInputContainer {
  width: 100%;
  max-width: 560px;
}

.QuestionContainer {
  & + & {
    margin-top: 20px;
  }
}

.Spinner {
  margin-top: 20px !important;
}
