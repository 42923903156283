.Container{
  width: 260px;
  margin-right: 0;
  margin-bottom: 0;
  background-color: var(--white);
  border: 1px solid var(--progress);

  .Progress{
    background: var(--new-light);
  }

  .Stat{
    right: 10px;
  }
}
