@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormTitleError {
  color: var(--brand-01)
}

.Subtitle {
  margin: 0 0 24px 0;
  @include text-16-reg;
  color: var(--text-01);
}

.Error {
  display: block;
  color: var(--support-error);
  margin-bottom: 20px;
}

.Button {
  width: 100%;
  margin-bottom: 32px;
}

.TextButton {
  display: block;
  margin: 0 auto;
}

.Link {
  display: block;
  margin-top: 24px;
  margin-left: 0;
  margin-right: 0;
  @include text-16-reg;
  color: var(--complementary-02);
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.SuccessImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.SuccessText {
  margin: 0 0 32px 0;
  text-align: center;
  @include text-24-bold;
  color: var(--text-01);
}