@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.DraggableContainer {
  margin: auto;
  width: fit-content;
  height: fit-content;
}

.IndicatorContainer {
  display: none;
}

@include media-breakpoint-down(sm) {
  .IndicatorContainer {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28px;
    z-index: 10;

    .Indicator {
      display: inline-block;
      width: 52px;
      height: 4px;
      background-color: var(--progress);
    }
  }
}