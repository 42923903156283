@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.MoreItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 20px;
  @include text-14-reg;
  color: var(--text-01);
  cursor: pointer;

  .NextIcon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) rotate(-90deg);
  }

  &:hover {
    background-color: var(--selection);

    .OptionsContainer {
      display: block;
    }
  }

  .OptionsContainer {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 261px;
    transform: translateX(-100%);
    background-color: var(--white);
    box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
    border-radius: 8px;
    overflow: hidden;

    .Option {
      position: relative;
      width: 100%;
      padding: 10px 20px 10px 36px;

      &:hover {
        background-color: var(--selection);
      }

      .CheckIcon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);

        path {
          fill: var(--complementary-02);
        }
      }
    }

    .Checked {
      color: var(--complementary-02);
    }
  }
}

.Danger {
  color: var(--support-error);
}