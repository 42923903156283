.ImgPlusButtons {
  .Img {
    display: block;
    width: 536px;
    height: 670px;
    border-radius: 8px;
  }

  .ButtonsContainer {
    padding-left: 24px;
  }
}