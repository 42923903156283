@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 689px;
    margin-right: 24px;

    .OptionType {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .Cash {
        height: 40px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      }

      .Voucher {
        height: 40px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }

      .Label {
        margin-right: 40px;
        @include text-14-reg;
        color: var(--text-01);
      }
    }
  }

  .PreviewContainer {
    width: 383px;

    .RedeemOption {
      width: 335px;
      height: 191px;
      padding: 20px;

      :global {
        .Price {
          @include text-20-bold;

          .Sum {
            font-size: 56px;
            line-height: 56px;
            margin-right: 8px;
          }

          .Currency {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .Title {
          width: 165px;
          margin-top: 12px;
          font-size: 16px;
          line-height: 24px;
          min-height: 48px;
        }

        .Footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .Points {
            background: var(--white);
            border-radius: 5px;
            padding: 3px 7px;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;

            svg {
              position: static;
              margin-left: 3px;
            }
          }

          .Limit {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .LogoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Logo {
        max-width: 120px;
      }

      .WithoutLogo {
        width: 100%
      }
    }
  }

  .ViewData {
    .SelectedUser {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 36px 14px 16px;
      margin-bottom: 24px;
      background-color: var(--new-light);
      border-radius: 12px;
      @include text-14-reg;

      .SelectedUserName {
        margin-right: 8px;
        color: var(--text-01);
      }

      .SelectedUserID {
        color: var(--text-03);
      }
    }
  }
}