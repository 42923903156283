@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.SubmitButton {
  margin-top: 24px;
  height: 50px;
  width: 280px;
  background: #ef3340;
  box-shadow: 0 14px 14px rgba(239, 51, 64, 0.16);
  border: 0;
  border-radius: 48px;
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #ffffff;
  cursor: pointer;
  outline: none;

  &:disabled {
    color: #ccc;
    background: rgba(239, 51, 64, 0.5);
    cursor: default;
  }
  .LoadingIndicator {
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;

    div::before {
      color: white;
      background-color: #fff;
    }
  }



 @include media-breakpoint-down(sm){
   width: 100%;
 }
}