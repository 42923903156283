.Swiper{
  height: 100%;

  :global{
    .swiper-pagination-bullet{
      background-color: #fff;
      opacity: 0.5;
    }
    .swiper-pagination-bullet-active{
      width: 10px;
      height: 10px;
      opacity: 0.8;
    }
  }
}

.Img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.PrevButton{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  left: 16px;
  top: calc(50% - 40px/2);
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 10;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}

.NextButton{
  left: initial;
  right: 16px;
  top: calc(50% - 40px/2);
  transform: rotate(180deg);
}