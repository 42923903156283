.Container {
  height: 77px;
  margin-top: 8px;
}

.Title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #303141;
}

.SelectedPreset {
  width: 277px;
  margin: 6px 0;
  padding: 14px 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  cursor: pointer;
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg-color);
  color: #303141;
}

.DropdownIcon {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 12px;
  float: right;
  cursor: pointer;
}
