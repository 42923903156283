@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  max-width: 1100px;
  width: 100%;
  padding-top: 32px;
  margin: 0 auto;

  .FormContainer {
    display: flex;

    .MainContainer {
      display: flex;
      flex-direction: column;
      width: 689px;
      margin-right: 24px;
    }

    .PreviewContainer {
      width: 383px;
    }

    .Section {
      padding: 32px 24px;
      background: var(--white);
      border-radius: 8px;

      .Title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        @include text-20-bold;
        color: var(--text-01);

        svg {
          margin-right: 10px;
        }
      }

      .Dropzone {
        width: 335px;
        height: 419px;
        filter: initial;
      }

      .Input {
        &::placeholder {
          color: var(--text-03);
        }
      }

      .Button {
        @include text-16-reg;
        color: var(--complementary-02);
      }

      .DescriptionContainer {
        position: relative;
        margin-bottom: 24px;
        margin-top: 24px;

        .DescriptionLength {
          position: absolute;
          right: 8px;
          bottom: 21px;
          color: var(--text-03);
          @include text-14-reg;
        }

        .DescriptionLabel {
          display: block;
          margin-bottom: 6px;
        }

        .DescriptionInput {
          width: 100%;
          height: 119px;
          padding-left: 16px;
          padding-top: 14px;
          padding-right: 16px;
          background: var(--new-light);
          border-radius: 12px;
          border: 0;
          resize: none;
          @include text-14-reg;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: var(--text-03);
          }
        }
      }

      .Label {
        @include text-14-reg;
        color: var(--text-03);
      }
    }

    .Section + .Section {
      margin-top: 24px;
    }
  }
}
