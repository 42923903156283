.Container {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 16px 24px;

  .Icon {
    margin-right: 9px;
    cursor: pointer;
  }

  .AddChannel {
    padding: 3px;
    margin-left: auto;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #F5F7FA;
    }
  }
}

.InputContainer {
    margin-top: 8px;
    margin-bottom: 8px;
  }