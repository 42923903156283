@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  width: 400px;
  height: 486px;

  .LogoContainer {
    width: 400px;
    height: 400px;
    border-radius: 6px;
    overflow: hidden;
  }

  .InfoContainer {
    position: relative;
    padding-top: 12px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;

    .Name {
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      color: var(--text-01);
    }

    .Info {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 160%;
      color: var(--text-02);

      span + span {
        margin-left: 16px;
      }
    }

    .More {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
}