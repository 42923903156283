.Container {
  width: 44px;
  height: 44px;
  background: var(--white);
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;

  span, span:before, span:after {
    width: 18px;
    position: absolute;
    height: 2px;
    background: var(--text-01);
    display: block;
    transition: .3s;
    border-radius: 5px;
  }

  span:before {
    content: "";
    top: -5px;
    transition: top 300ms 300ms, transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  span:after {
    content: "";
    bottom: -5px;
    transition: bottom 300ms 300ms, transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  span{
    width: 13px;
    left: 50%;
    top: 50%;
    transform: translate(-10px, -50%);
  }

  span.active:before {
    content: "";
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms, transform 300ms 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  span.active:after {
    content: "";
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms, transform 300ms 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  span.active {
    background: rgba(255, 255, 255, 0);
  }
}

