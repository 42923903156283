@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.ItemsContainer {
  padding: 32px 24px;
  background: var(--white);
  border-radius: 0 8px 8px 8px;

  .ItemsHeader {
    margin-bottom: 16px;

    .Title {
      @include text-20-bold;
      color: var(--text-01);
      margin-right: 24px;
    }
  }

  .OffersTableHeader {
    display: grid;
    grid-template-columns: minmax(34%, 564px) minmax(16px, 32px) 80px minmax(16px, 32px)  80px minmax(16px, 32px) 80px minmax(16px, 32px) 76px;
    grid-template-rows: auto;
    grid-template-areas:
      "name . claims . reach . engagement . status";
    align-items: center;
    @include text-14-reg;
    color: var(--new);
    margin-bottom: 16px;

    .Name {
      grid-area: name;
    }

    .Claims {
      grid-area: claims;
      justify-self: end;
    }

    .Reach {
      grid-area: reach;
      justify-self: end;
    }

    .Engagement {
      grid-area: engagement;
      justify-self: end;
    }

    .Status {
      grid-area: status;
      justify-self: end;
    }
  }

  .OfferTableItem {
    display: grid;
    grid-template-columns:40px 16px  minmax(26%, 508px) minmax(16px, 32px) 80px minmax(16px, 32px)  80px minmax(16px, 32px) 80px minmax(16px, 32px) auto;
    grid-template-rows: auto;
    grid-template-areas:
      "logo . name . claims . reach . engagement . status";
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    border-bottom: 1px solid var(--selection);
    padding-top: 16px;
    padding-bottom: 16px;
    color: var(--text-01);

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    .Logo {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      grid-area: logo;
    }

    .Name {
      font-size: 14px;
      grid-area: name;
    }

    .Claims {
      grid-area: claims;
      justify-self: end;
    }

    .Reach {
      grid-area: reach;
      justify-self: end;
    }

    .Engagement {
      grid-area: engagement;
      justify-self: end;
    }

    .Status {
      grid-area: status;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .VoucherTableHeader {
    display: grid;
    grid-template-columns: 480px 32px 480px;
    grid-template-rows: auto;
    grid-template-areas:
      "Title . Description";
    align-items: center;
    @include text-14-reg;
    color: var(--new);

    .Title {
      grid-area: Title;
    }

    .Description {
      grid-area: Description;
    }
  }

  .VoucherTableItem {
    display: grid;
    grid-template-columns: 40px 16px 424px 32px 480px 38px auto;
    grid-template-rows: auto;
    grid-template-areas:
      "Logo . Title . Description . More";
    align-items: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-01);
    border-bottom: 1px solid var(--selection);
    padding-top: 16px;
    padding-bottom: 16px;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    .Logo {
      grid-area: Logo;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
    }

    .Title {
      grid-area: Title;
      font-weight: bold;
      line-height: 18px;
    }

    .Description {
      grid-area: Description;
    }

    .More {
      grid-area: More;
    }
  }

  .ItemsFooter {
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
      @include text-16-reg;
      background-color: var(--white);
      color: var(--complementary-02);
      margin-top: 16px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}