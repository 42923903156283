@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  .MoreOptions {
    position: absolute;
    right: 100%;
    top: 0;
    @include text-14-bold;
    display: flex;
    align-items: center;
    color: var(--text-01);
    will-change: filter;
    filter: drop-shadow(0px 15px 45px rgba(48, 49, 65, 0.25));
    cursor: pointer;
    z-index: 1;

    .ChangeStatus {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 136px;
      height: 48px;
      padding: 15px 16px;
      background-color: var(--white);
      border-radius: 2px 12px 12px 2px;

      &:hover {
        background-color: var(--selection);
      }

      .Statuses {
        position: absolute;
        left: -106px;
        top: 0;
        border-radius: 4px 2px 4px 4px;
        overflow: hidden;

        .Status {
          width: 106px;
          height: 48px;
          padding: 15px 16px;
          background-color: var(--white);

          &:hover {
            color: var(--support-error);
          }
        }
      }
    }
  }
}