.Container{
  display: flex;
  background-color: #fff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;

  .TabContainer{
    width: 100%;
    padding: 10px 20px 20px 16px;
  }

  .Posts{
    overflow-y: scroll;
    height: 416px;
  }
}