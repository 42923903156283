.CategoryContainer{
    position: relative;
    width: 100%;
}

.DropdownContainer{
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    max-height: 240px;
    background: #FFFFFF;
    overflow-y: scroll;
    box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
    border-radius: 4px;
    z-index: 2;
}

.DropdownItem{
    display: flex;
    align-items: center;
    padding: 14px 16px;
}
.DropdownItem img{
    margin-right: 8px;
}

.DropdownItem:hover{
    background-color: #F0F1FB;
}

.InputContainer{
    position: relative;
}


.InputContainer img{
    position: absolute;
    right: 20px;
    top: calc(50% + 4px);
    transform: translateY(-50%);
}


.Input{
    width: 100%;
    height: 48px;
    padding: 14px 40px 14px 16px;
    margin-top: 8px;
    background: var(--input-bg-color);
    border: none;
    border-radius: var(--input-border-radius);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
}

.InputError{
    border: 1px solid #FF5722;
}
