@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.CreateOfferScheduleCustomDayTimeInterval {
  display: flex;
  flex-direction: row;
  list-style-type: none;

  & + & {
    margin-top: 16px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.TimePickerContainer {
  width: 150px;
}

.FromLabel {
  position: relative;
  top: 4px;
  margin: auto 16px auto 0;
  vertical-align: middle;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--black);
}

.ToLabel {
  position: relative;
  top: 4px;
  margin: auto 16px;
  @include text-14-reg;
  color: var(--black);

  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.StartEndTime {
  width: 148px;
  height: 48px;

  align-items: center;
  justify-content: center;
  text-align: center;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
  background: #f4f4f4;
  border-radius: 4px;
  cursor: pointer;
}

.StartEndTimeDropdownIcon {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 0;
  float: right;
  cursor: pointer;
}

.AddRemoveRangeContainer {
  position: relative;
  top: 4px;
  margin-left: 16px;
  align-content: center;
  align-self: center;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    align-self: initial;
    margin-left: 0;
  }
}

.AddRemoveRangeIcon {
  padding: 0;
  cursor: pointer;
}

.AddRemoveRangeText {
  vertical-align: top;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4px;
  @include text-16-reg;
  align-items: center;
  color: var(--complementary-02);
  cursor: pointer;
}

.AddRemoveRangeText:hover {
  text-decoration: underline;
}
