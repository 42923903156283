@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  width: 272px;
  height: 155px;
  padding: 15px;
  border-radius: 8px;
  background-image: url('../../../shared/icons/RedeemCardBG.svg');
  background-position: right 0 bottom 0;
  background-repeat: no-repeat;
  @include text-14-reg;
  color: var(--text-01);
  overflow: hidden;

  .MainContainer {
    display: flex;
    justify-content: space-between;
  }

  .LogoContainer {
    max-width: 48px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  :global {
    .Price {
      @include text-20-bold;

      .Sum {
        font-size: 46px;
        line-height: 45px;
        margin-right: 7px;
      }

      .Currency {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .Title {
      width: 165px;
      margin-top: 10px;
      font-size: 13px;
      line-height: 19px;
      min-height: 39px;
    }

    .Footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;


      .Points {
        background: var(--white);
        border-radius: 5px;
        padding: 3px 7px;
        font-size: 13px;
        font-weight: bold;

        svg {
          position: static;
          margin-left: 3px;
        }
      }


      .Limit {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }

  .NotActive {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    opacity: 0.5;
  }


  .MoreIconContainer {
    position: absolute;
    right: 14px;
    top: 9px;

    svg {
      cursor: pointer;

      path {
        fill: #333333
      }

      &:hover {
        path {
          fill: #EF3340;
        }
      }
    }
  }

  .NotActive + .MoreIconContainer {
    svg {
      path {
        fill: rgba(#333333, 0.5)
      }

      &:hover {
        path {
          fill: #EF3340;
        }
      }
    }
  }
}
