@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;
  height: 500px;
  background-color: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;
  margin: auto;
  overflow: hidden;

  .ImgContainer {
    margin-right: 16px;

    .Img {
      display: block;
      width: 400px;
      height: 500px;
      border-radius: 6px;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .TextPlusComments {
    display: flex;
    flex-direction: column;
    width: 100%;

    .Description {
      padding: 16px 30px 4px 8px;
      border-radius: 8px;

      .Header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .More {
          margin-left: auto;
        }
      }

      .Text {
        @include text-16-reg;
        color: var(--text-01);
      }
    }

    .Reported {
      background-color: var(--light-red);
    }

    .Comments {
      height: 100%;
      padding-left: 8px;
      overflow-y: scroll;
    }
  }
}