.Container{
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .Title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #303141;
  }

  button{
    border: none;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .BackButton{

    &:hover{
      cursor: pointer;
    }
  }

  .DangerButton{
    display: inline-block;
    color: #FF5722;

    &:hover{
      cursor: pointer;
    }
  }
  .DangerButton+.DangerButton{
    margin-left: 0;
  }

  .SuccessButton{
    display: inline-block;
    color: #1F2A99;
    &:hover{
      cursor: pointer;
    }
  }

  .ActivateButton{
    display: inline-block;
    color: #37B9AA;
    &:hover{
      cursor: pointer;
    }
  }

  .Disabled{
    color: #C1C1C6;
    &:hover{
      cursor: not-allowed;
    }

  }

  button{
    margin-right: 24px;
  }

  button:first-of-type{
    margin-left: auto;
  }

  button:last-of-type{
    margin-right: 0;
  }

  .Status{
    margin-left: auto;
  }
}
