.Container{
  display: flex;
  background-color: #fff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;

  .Posts{
    overflow-y: scroll;
    height: 586px;
    width: 100%;
  }
}