@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;
  padding: 0 16px;
  margin-bottom: 24px;

  .Logo {
    width: 96px;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 16px;
  }

  .Post {
    width: 100%;
    @include text-16-reg;
    color: var(--text-01);

    .Header {
      display: flex;
      align-items: center;

      .Info {
        width: 100%;
        display: flex;
        align-items: center;
        @include text-14-reg-jost;
        color: var(--text-02);
      }

      .More {
        margin-left: auto;
      }
    }

    .Text {
      margin-top: 10px;
      margin-bottom: 8px;
      word-break: break-word;
    }

    .Buttons {
      font-weight: 700;
      display: flex;
      align-items: center;

      svg {
        margin-right: 4px;
      }
    }
  }
}