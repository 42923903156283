@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.CategoryFilter {
  :global {
    .InputContainer {
      width: 240px;
    }

    .OptionsContainer {
      width: 215px;

      .Option {
        width: initial;
        height: initial;
        @include text-14-bold;
        color: var(--text-01);
      }
    }
  }
}

.DatePickerWrapper {
 & > input {
   width: 140px;
    height: 40px;
  }
}

  .Header {
    grid-template-columns: 252px 32px 29px 32px 200px 32px 200px 32px 55px;
    grid-template-rows: auto;
    grid-template-areas:
      "user . date . targetUser . category . points";

    .User {
      grid-area: user;
    }

    .Date {
      grid-area: date;
    }

    .TargetUser {
      grid-area: targetUser;
    }

    .Category {
      grid-area: category;
    }

    .Points {
      grid-area: points;
      justify-self: end;
    }
  }

  .Item {
    grid-template-columns: 226px 32px 55px 32px 200px 32px 200px 32px 55px;
    grid-template-rows: auto;
    grid-template-areas:
      "user . date . targetUser . category . points";
    cursor: pointer;

    &:hover {
      background-color: var(--bg);
    }

    &:last-child::after {
      display: none;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .User {
      grid-area: user;
    }

    .Date {
      grid-area: date;
      justify-self: end;
    }

    .TargetUser {
      grid-area: targetUser;
    }

    .Category {
      grid-area: category;
    }

    .Points {
      grid-area: points;
      justify-self: end;
      display: flex;
      align-items: center;

      span {
        margin-right: 4px;
      }
    }
  }

.ChartContainer {
  background-color: var(--white);
  border-radius: 8px;
  padding-bottom: 24px;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 16px;

    .Title {
      @include text-20-bold;
      color: var(--text-01);
    }

    .Button {
        color: var(--complementary-02)
    }
  }
}