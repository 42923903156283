@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 458px 32px 65px 32px 94px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "content . createdAt . reportedBy . reason";

  .Content {
    grid-area: content;
  }

  .CreatedAt {
    grid-area: createdAt;
  }

  .ReportedBy {
    grid-area: reportedBy;
  }

  .Reason {
    grid-area: reason;
  }
}

.ReportItem {
  grid-template-columns:8px 16px 45px 16px 375px 32px 65px 32px 94px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "newReport . logo . content . createdAt . reportedBy . reason ";

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    background-color: var(--bg);
    cursor: pointer;
  }

  .NewReport {
    grid-area: newReport;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--brand-01);
    }
  }

  .Logo {
    grid-area: logo;
    width: 45px;
    height: 56px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  .ImgPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--new-light);

    svg {
      path {
        fill: var(--text-01);
      }
    }

    .Letter {
      @include text-18-bold;
      color: var(--text-03);
    }
  }

  .Content {
    grid-area: content;
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 4px;
      @include text-14-reg;
      color: var(--text-03);

      &.Title {
        font-weight: 700;
        color: var(--text-01);
      }
    }
  }

  .CreatedAt {
    grid-area: createdAt;
  }

  .ReportedBy {
    grid-area: reportedBy;
  }

  .Reason {
    grid-area: reason;
  }

  .More {
    grid-area: more;
    justify-self: end;
  }

}

.SearchContainer {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-bottom: 8px;
}
