@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  height: 619px;

  .Header {
    padding-left: 20px;
    margin-bottom: 15px;
  }

  .Img {
    display: block;
    width: 100%;
    height: 400px;
    border-radius: 8px;
  }

  .Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-top: 8px;
    margin-bottom: 12px;

    .SocialButtons {
      display: flex;
      align-items: center;

      .Like {
        cursor: pointer;

        &:hover {
          path {
            stroke: var(--brand-01);
          }
        }
      }

      .LikeActive {
        fill: var(--brand-01);
        cursor: pointer;

        path {
          stroke: var(--brand-01);
        }
      }

      svg {
        margin-left: 20px;

        &:nth-of-type(2) {
          margin-left: 30px;
        }
      }

      span {
        @include text-16-bold;
        color: var(--text-01);
        margin-left: 7px;
      }
    }
  }

  .PostText {
    width: 320px;
    max-height: 110px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: scroll;
    @include text-16-bold;
    color: var(--text-01);
  }
}