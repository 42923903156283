@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;
  align-items: center;
  @include text-14-reg;
  line-height: 18px;

  .Logo {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    margin-right: 16px;
    object-fit: cover;
  }

  .TextAndId {
    display: flex;
    flex-direction: column;
    max-width: 589px;
    margin-right: 48px;

    .Text {
      margin-bottom: 8px;
      font-weight: bold;
      color: var(--text-01);
    }

    .Id {
      line-height: 20px;
      color: var(--text-03);
    }
  }

  .Points {
    display: flex;
    align-items: center;
    margin-left: auto;

    span {
      margin-right: 4px;
    }
  }

  .TargetUsers {
    margin-left: 30px;
  }

  .TargetBusiness {
    margin-left: 30px;
  }
}