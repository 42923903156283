@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 302px 32px 302px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "Title . Text . Target";

  .Title {
    grid-area: Title;
  }

  .Text {
    grid-area: Text;
  }

  .Target {
    grid-area: Target;
  }
}

.TemplateItem {
  grid-template-columns: 45px 16px 241px 32px 302px 32px 140px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "Logo . Title . Text . Target . More";

  &:hover {
    background-color: var(--bg);
    cursor: pointer;
  }

  .Logo {
    grid-area: Logo;
    width: 45px;
    height: 56px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  .Title {
    grid-area: Title;
    @include text-14-bold;
  }

  .Text {
    grid-area: Text;
  }

  .Target {
    grid-area: Target;
  }

  .More {
    grid-area: More;
  }
}