@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.CheckboxLabel {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  width: fit-content;
  min-height: 22px;

  .CheckboxBox {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: var(--white);
    border: 2px solid var(--text-03);
    border-radius: 6px;

    svg {
      display: none;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled ~ .CheckboxBox {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:disabled ~ .CheckboxText {
      opacity: 0.4;
      cursor: not-allowed;
    }

  }

  input[type="checkbox"]:checked ~ .CheckboxBox {
    border: none;
    background-color: var(--complementary-01);

    svg {
      display: block;
    }
  }

  input[type="checkbox"]:checked ~ .CheckboxBox::after {
    display: block;
  }

  .CheckboxText {
    margin-left: 12px;
  }
}
