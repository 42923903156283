.ModalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 25;

  .Container {
    position: relative;
    width: 906px;
    padding: 56px 40px 40px 40px;
    background: #FFFFFF;
    box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
    border-radius: 6px;

    .CloseIcon {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      rect {
        fill: #303141;
      }

      &:hover{
        rect {
          fill: #EF3340;
        }
      }
    }

    .Title{
      font-family: Jost, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 34px;
      color: #303141;
    }

    .Subtitle{
      margin-top: 24px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #303141;
    }

    .ButtonsContainer{
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;

      div + div {
        margin-left: 16px;
      }
    }
  }
}