.Container{
  width: 400px;
  height: 456px;

  .LogoContainer{
    width: 400px;
    height: 400px;
    border-radius: 6px;
    overflow: hidden;
  }

  .InfoContainer{
    position: relative;
    padding-top: 12px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;

    .Name{
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      color: #303141;
    }

    .More{
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
}