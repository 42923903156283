.Container {
  .Tabs {
    & > div:first-child {
      width: fit-content;
      overflow: hidden;
      border: 1px solid #E4E6F7;
      border-radius: 12px;

      button {
        min-width: 106px;
      }
    }
  }

  .TabsWithSearchBar {
    & > div:first-child {
      width: fit-content;
      overflow: hidden;
      border: 1px solid #E4E6F7;
      border-bottom: none;
      border-radius: 12px 12px 0 0;

      button {
        min-width: 106px;
      }
    }
  }

  .SearchBarContainer {
    margin-top: -1px;
    background-color: #FFFFFF;
    border: 1px solid #E4E6F7;
    border-radius: 0 12px 12px 12px;

    .TextArea {
      width: 100%;
      padding: 14px 16px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5E5F70;
      background-color: #F5F7FA;
      border-radius: 12px;
      border: none;
      outline: none;
      resize: none;
    }

    .SearchBar {
      padding: 12px 16px;

      .InputContainer {

      }

      .SearchItem {
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-top: 8px;
        padding: 6px 36px 6px 8px;
        border-radius: 12px;
        background-color: #F5F7FA;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #303141;
        cursor: pointer;

        &:hover {
          background-color: #F0F1FB;
        }

        .Logo {
          img {
            width: 24px;
            height: 24px;
          }

          margin-right: 8px;
        }

        .Name {
          margin-right: 8px;
        }

        .Id {
          color: #C1C1C6;
        }

        .Close {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #fff;

          &:hover {
            .CloseIcon {
              rect {
                fill: #EF3340;
              }
            }
          }
        }
      }
    }

    .Options {

      border-top: 1px solid #E4E6F7;
      max-height: 480px;
      overflow-y: auto;

      .Option {
        display: flex;
        align-items: center;
        padding: 6px 16px;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #303141;

        .Avatar {
          margin-right: 8px;
        }

        .Name {
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .Id {
          margin-left: auto;
          color: #C1C1C6;
        }

        &:hover {
          background-color: #F0F1FB;
        }
      }

    }
  }

}