@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  .SocialButtons {
    display: flex;
    align-items: center;

    .LikeActive {
      fill: var(--brand-01);

      path {
        stroke: var(--brand-01);
      }
    }

    svg {
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 30px;
      }
    }

    span {
      @include text-16-bold;
      color: var(--text-01);
      margin-left: 6px;
    }
  }

  .Right {
    margin-left: auto;
  }

  .RightMargin {
    margin-right: 4px;
  }

  .CopyButton {
    min-width: 167px;
  }
}