.Container{
  width: 400px;
  height: 586px;

  .LogoContainer{
    width: 400px;
    height: 500px;

    .Logo{
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .InfoContainer{
    position: relative;
    padding-top: 12px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    .Name{
      text-align: center;
      font-size: 24px;
      line-height: 32px;
      color: #303141;
    }

    .Info{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 160%;
      color: #9798A0;

      span+span{
        margin-left: 16px;
      }
    }

    .More{
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
}