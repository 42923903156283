.OfferPreview {
  max-width: 320px;
  height: 649px;
  display: block;
  padding: 16px 0;
  /*height: fit-content;*/
  background: #ffffff;
  border-radius: 8px;
}

.OfferPreviewHeader {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.OfferPreviewImageContainer {
  position: relative;
  width: 320px;
  height: 396px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.BusinessLogo {
  display: block;
  height: 44px;
  width: 44px;
  margin-right: 10px;
}

.BusinessName {
  display: inline-block;
  height: 28px;
  margin-bottom: 4px;

  font-family: Jost;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;

  color: #303141;
}

.OfferTime {
  margin-right: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #303141;
}

.LocationIcon {
  width: 12px;
  height: 12px;
}

.OfferLocation {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #303141;
  margin-left: 5px;
}

.ImagePlaceholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dashed #C1C1C6;
  border-radius: 6px;
}

.OfferImage {
  width: 320px;
  height: 396px;
  border-radius: 6px;
}

.TitleAndText{
  max-height: 110px;
  overflow: scroll;
}

.OfferTitle {
  word-wrap: break-word;
  margin-bottom: 2px;
  margin-left: 20px;
  margin-top: 0;
  margin-right: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  color: #303141;
}

.OfferDescription {
  word-wrap: break-word;
  margin: 0 20px 0 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #303141;
}

.FirstLetter {
  background: rgba(48, 49, 65, 0.05);
  line-height: 44px;
  text-align: center;
  border-radius: 20px;
  font-family: Jost;
  font-style: normal;
  font-weight: bold;
  display: block;
  height: 44px;
  width: 44px;
}

.ButtonsContainer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 12px;
}
.LikeContainer{
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.CommentsContainer{
  display: flex;
  align-items: center;
}

.SharedIcon{
}

.Number{
  margin-left: 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  color: #303141;
}

.CoinsContainer{
  position: absolute;
  right: 0;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 10px 0 12px;
  background: #FFFFFF;
  border-radius: 6px 0 0 6px;
}

.CoinsText{
  margin-right: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}


