.TimePickerContainer{
  position: relative;
  display: flex;
  align-items: center;
  & > span{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
    margin-right: 8px;
  }
}
.TimeDropdownContainer{
  padding-top: 0 !important;
  :global{
    .react-datepicker{
      border: none;
      box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
      padding: 0 !important;
      border-radius: 12px;
      overflow: hidden;

    }
    .react-datepicker__header--time--only{
      display: none;
    }
    .react-datepicker__time{
      border: none;
      border-radius: 4px;

      .react-datepicker__time-list-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 16px !important;
        height: 48px !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #303141;

        &:hover{
          background-color: #F0F1FB !important;
        }
      }

      .react-datepicker__time-list-item--selected{
        background-color: #3C49C6 !important;
      }
    }
    .react-datepicker__time::-webkit-scrollbar {
      background-color: #fff;
    }
    .react-datepicker__triangle{
      display: none;
    }
    .react-datepicker__time-container{
      width: 100px;
    }
    .react-datepicker__time-box{
      width: 100px !important;
    }

  }
}

.TimePickerInput{
  width: 100%;
  padding: 14px 20px 14px 16px;
  background-color: #F5F7FA;
  color: #303141;
  border: none;
  outline: none;
  border-radius: var(--input-border-radius);
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.DownIcon{
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}