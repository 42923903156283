@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  & + & {
    margin-top: 24px;
  }

  .Answer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 260px;
    min-height: 48px;
    margin-right: 8px;
    border-radius: 12px;


    input {
      width: 100%;
      padding: 14px 16px;
      @include text-14-reg;
      color: var(--text-01);
      background-color: var(--input-bg-color);
      border-radius: 12px;
      border: none;
      outline: none;

      &::placeholder {
        color: var(--text-03);
      }
    }
  }

  .DragIconContainer {
    position: absolute;
    left: 0;
    top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: var(--white);
    opacity: 0.8;
    border-radius: 8px;
    z-index: 1;
  }

  .IconContainer {
    position: absolute;
    top: 37px;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: var(--white);
    opacity: 0.8;
    border-radius: 50%;
    cursor: pointer;

    &:hover{
      background-color: darken(#FFFFFF,2);

      svg path{
        fill: darken(#FF5722,2);
      }
    }
  }

  .Tags {
    width: 100%;
  }
}
