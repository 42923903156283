@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  padding: 30px 24px;
  background: #ffffff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;
}

.Title {
  display: block;
  height: 24px;
  @include text-20-bold;
  color: var(--text-01);
  margin-bottom: 32px;
}

.Subtitle {
  display: block;
  @include text-18-bold;
  color: var(--text-01);
  margin-bottom: 24px;
}

.ClaimOnlyOnceContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.ClaimLocationContainer {
  margin-bottom: 18px;
  @include text-16-reg;
  align-items: center;
  display: flex;
  color: var(--text-01);
  cursor: pointer;
}

.AddressInput {
  margin-bottom: 16px;
}

.MapContainer {
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
}

.ClaimLimitTitle {
  display: block;
  margin-top: 24px;
  @include text-14-reg;
  align-items: center;
  letter-spacing: 0.25px;
  color: var(--text-01);
}

.ClaimOnlyOnceCheckbox {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  cursor: pointer;
}

.ClaimOnlyOnceLabel {
  @include text-16-reg;
  color: var(--text-01);
  cursor: pointer;
}

.GenderFilterContainer {
  margin-top: 24px;
  margin-bottom: 40px;
}

.GenderFilterLabel {
  display: inline-block;
  @include text-14-reg;
  line-height: 25px;
  align-items: center;
  color: var(--text-01);
  margin-right: 40px;
}

.GenderFilterList {
  display: inline-flex;
  list-style-type: none;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.GenderFilterListItem {
  margin-right: 8px;
  padding: 8px 24px;
  @include text-14-reg;
  font-weight: 500;
  align-items: center;
  text-align: center;
  letter-spacing: -0.2px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}