@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.InputContainer {
  min-width: 312px;
  margin-bottom: 24px;
}

.UserInputContainer {
  min-width: 312px;
  margin-bottom: 24px;
  display: flex;
}

.Input {
  &::placeholder {
    color: var(--text-03);
  }
}

.UserInput {
  flex: 1;
}

.GoToUserButton {
  align-self: end;
  margin-left: 16px;
}