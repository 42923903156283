@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  grid-template-columns: 322px 1fr;
  grid-column-gap: 32px;
  padding: 40px;
  max-width: 1160px;
  background: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 0 0 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }

  .PreviewContainer {
    border: 1px solid #E4E6F7;
    border-radius: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .StatisticsContainer {
    width: 100%;

    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }

    .Header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include text-14-reg;
      color: var(--text-01);

      @include media-breakpoint-down(lg) {
        padding: 0 16px;
      }

      @include media-breakpoint-down(sm) {
        row-gap: 4px;
      }

      .TimeframeStart {
        display: flex;
        align-items: center;
      }

      .TimeframeEnd {
        display: flex;
        align-items: center;
      }

      .Timeframe {
        width: 90px;
        height: 20px;
        @include media-breakpoint-down(sm) {
          width: 90px;
        }

        input {
          width: 100%;
          padding: 0 14px;
          @include text-14-bold;
          color: var(--complementary-02);
        }
      }

      .Label {
        flex-shrink: 0;
        @include text-14-reg;
        color: var(--text-01);
      }

      .TotalImpression {
        display: flex;
        align-items: center;

        span {
          margin-right: 4px;
        }
      }
    }

    .ChartsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-top: 14px;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
      }
    }
  }
}