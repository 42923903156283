@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .ImageDropzoneHintLine1 {
    margin-top: 12px;
    color: var(--text-01);
  }

  .ImageDropzoneHintLine2 {
    margin-top: 4px;
    color: var(--text-03);
  }

  .ImageDropzoneHintLine3 {
    height: 18px;
    line-height: 18px;
    margin-top: 4px;
    text-decoration-line: underline;
    cursor: pointer;
    color: var(--complementary-02);
  }
}