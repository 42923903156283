@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 456px 32px 40px 32px 80px 32px 80px 32px 80px 120px;
  grid-template-rows: auto;
  grid-template-areas:
      "Promotion . Points . TargetUsers . TargetBusinesses . CreatedAt .";

  .Promotion {
    grid-area: Promotion;
  }

  .Points {
    grid-area: Points;
  }

  .TargetUsers {
    grid-area: TargetUsers;
    justify-self: end;
  }

  .TargetBusinesses {
    grid-area: TargetBusinesses;
    justify-self: end;
  }

  .CreatedAt {
    grid-area: CreatedAt;
  }
}

.Item {
  grid-template-columns:72px 16px 368px 32px 60px 12px 80px 32px 80px 32px 80px 32px 35px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "Logo . Promotion . Points . TargetUsers . TargetBusinesses . CreatedAt . More";

  .Logo {
    grid-area: Logo;
    width: 72px;
    height: 72px;
    border-radius: 8px;
  }

  .Promotion {
    grid-area: Promotion;
  }

  .Points {
    grid-area: Points;
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }

  .TargetUsers {
    grid-area: TargetUsers;
    justify-self: end;
  }

  .TargetBusinesses {
    grid-area: TargetBusinesses;
    justify-self: end;
  }

  .CreatedAt {
    grid-area: CreatedAt;
  }

  .More {
    grid-area: More;
  }
}