.Tag{
  margin-right: 8px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #5E5F70;
  cursor: pointer;
}
