.MoreIconContainer {
  position: relative;
  top: 3px;

  .MoreContainer{
    position: absolute;
    top: 0;
    right: 30px;
    padding: 10px 0;
    width: 170px;
    background: #FFFFFF;
    box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
    border-radius: 8px;
    z-index: 1;
  }

  .Vertical{
    top: 16px;
    right: -15px;
  }

  svg {
    cursor: pointer;
    &:hover {
      path {
        fill: #EF3340;
      }
    }
  }
}