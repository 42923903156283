.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 8px 6px 12px;
  background: #FFFFFF;
  border-radius: 10px;




  .Title{
    margin-right: 6px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
  }

  .CloseIcon{
    padding: 3px;
    width: 16px;
    height: 16px;
    background-color: #F5F7FA;
    border-radius: 50%;
    cursor: pointer;

    &:hover{
      background-color: darken(#F5F7FA,3);
    }
  }
}
