@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Text {
  @include text-14-reg;

  .Title {
    color: var(--text-01);
  }

  .User {
    color: var(--text-02);
  }
}