@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  height: 25px;
  padding-right: 32px;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    &+& {
      margin-top: 8px;
    }
  }

}

.Icon {
  height: 25px;
  width: 25px;
}

.Text {
  margin-left: 15px;
  vertical-align: super;
  @include text-16-reg;
  color: var(--text-01);
}
