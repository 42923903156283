@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 689px;
    margin-right: 24px;
  }

  .PreviewContainer {
    width: 383px;
  }

  .Label {
    display: block;
    margin-bottom: 8px;
    @include text-14-reg;
  }

  .DateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .DatePicker {
      width: 312px;
      height: 48px;

      color: var(--text-01);
      background-color: var(--input-bg-color);
      border-radius: var(--input-border-radius);
      margin-top: 0;

      &::placeholder {
        color: var(--text-03);
      }

      svg {
        top: 17px;
      }
    }
  }

  .ViewData {
    .SelectedUser {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 36px 14px 16px;
      margin-bottom: 24px;
      background-color: var(--new-light);
      border-radius: 12px;
      @include text-14-reg;

      .SelectedUserName {
        margin-right: 8px;
        color: var(--text-01);
      }

      .SelectedUserID {
        color: var(--text-03);
      }
    }
  }

  .Dropzone {
    width: 335px;
    height: 188px;
    filter: initial;
  }

  .PreviewImg {
    width: 335px;
    height: 188px;
    object-fit: cover;
    border-radius: 12px;
  }
}