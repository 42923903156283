.DatePickerContainer{
  :global{
    .react-datepicker__day--selected{
      background-color: transparent;
      &:hover{
        background-color: transparent;
        span{
          background-color: #3C49C6;
        }
      }
      span{
        border-radius: 50%;
        background-color: #3C49C6;
        color: #FFFFFF;
        &:hover{
          border-radius: 50%;
          background-color: #3C49C6;
          color: #FFFFFF;
        }
      }
    }

    .react-datepicker-popper[data-placement^=bottom] {
      padding: 0;
    }
  }
}

.CalendarHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;

  .MonthAndYear{
    display: flex;
    align-items: center;
  }

  button{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .DownIcon{
    margin-left: 2px;
    cursor: pointer;
  }

}

.YearsContainer{
  position: absolute;
  left: 0;
  top: 35px;
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  background-color: #fff;
  z-index: 3;

  .Year{
    display: flex;
    align-items: center;
    justify-content: center;
    color: unset;
    background-color: transparent;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin: 8px 0;
    height: 36px;
    width: 72px;
    border-radius: 18px;
    cursor: pointer;

    &:hover{
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
