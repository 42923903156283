@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 681px;
    margin-right: 24px;
    flex-shrink: 0;
  }

  .PreviewContainer {
    width: 383px;
  }

  .Preview {
    position: relative;
    z-index: 1;
  }

  .DropZone {
    width: 335px;
    height: 188px;
    filter: none;
    margin-bottom: 24px;
  }
}

  .Text {
    @include text-14-reg;
    color: var(--text-01);
    margin-top: 16px;
  }