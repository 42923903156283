@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .InputGroup {
    margin-bottom: 24px;

    .Title {
      margin-bottom: 8px;
      @include text-14-reg;
      color: var(--text-01);
    }

    div:nth-child(2) {
      input {
        border-radius: 12px 12px 0 0;
      }
    }

    div:nth-child(n + 3) {
      input {
        border-radius: 0;
      }
    }

    div:last-child {
      input {
        border-radius: 0 0 12px 12px;
      }
    }

    div:nth-child(2):last-child {
      input {
        border-radius: 12px;
      }
    }

    div + div {
      margin-top: 2px;
    }
  }
}