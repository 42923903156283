@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  flex-grow: 1;

  .SearchIcon {
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
  }

  .CloseIcon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .Input {
    width: 100%;
    height: 40px;
    padding: 10px 44px;
    background: var(--white);
    border-radius: 12px;
    @include text-14-reg;
    border: none;
    outline: none;

    ::placeholder {
      color: var(--new);
    }
  }
}
