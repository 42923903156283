
.Dropzone {
  width: auto;
  max-height: 300px;
  filter: initial;
  margin-bottom: 24px;
}

.WithoutBorder {
  border: none;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 335px;
  height: 335px;
  margin-bottom: 24px;
}
