@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Qr {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  background: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 27px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    box-shadow: 0px -14px 14px rgba(0, 0, 0, 0.04);
  }

  canvas {
    width: 100% !important;
    max-width: 550px;
    height: 100% !important;
  }
}