.OptionsContainer {
  position: absolute;
  width: 166px;
  max-height: 240px;
  left: 0;
  top: 40px;
  background: #FFFFFF;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 5;

  :hover {
    background-color: #F0F1FB;
    cursor: pointer;
  }

  .Option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    width: 166px;
    height: 48px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    .OptionTitle {
      color: #303141;
    }

    .OptionAmountOfPolls {
      color: #C1C1C6;
    }
  }
}

.CreateTagContainer{
  background: #FFFFFF;
  color: #3C49C6;
  z-index: 5;
  padding: 14px 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background-color: #F0F1FB;
    cursor: pointer;
  }

  .Icon{
    margin-right: 7px;
  }
}

.IsAlone {
  border-radius: 2px 2px 12px 12px;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
}