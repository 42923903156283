.Container{
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #F2F2F2;
  overflow: hidden;

  .Progress{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #F2F2F2;
    z-index: 2;
  }

  .Icon{
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    z-index: 3;
    vertical-align: middle;
  }

  .Title{
    position: relative;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    z-index: 3;
    vertical-align: middle;
  }

  .Stat{
    position: absolute;
    right: 0;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5E5F70;
    z-index: 3;
  }
}
