@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 689px;
    margin-right: 24px;
  }

  .PreviewContainer {
    width: 383px;

    .GalleryContainer {
      margin-top: 24px;

      .Gallery {
        grid-template-columns: repeat(2, 1fr);
        gap: 13px;
      }

      .ItemStyle {
        width: 159px;
        height: 159px;

        &:nth-child(2) {
          grid-column: 1 / span 2;
          width: 100%;
          height: 336px;
        }
      }

      .GalleryDropzoneWithMedia {
        width: 159px;
        height: 159px;
        order: 2;
      }

      .GalleryDropzoneWithoutMedia {
        width: 100%;
        grid-column: 1 / span 2;
      }
    }
  }

  .Buttons {
    display: flex;
    align-items: center;

    .Left {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .Right {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .Subtitle {
      margin-right: 40px;
      @include text-14-reg;
      color: var(--text-01);
    }
  }

  .InputInfo {
    background-color: var(--white);
    border: 2px solid var(--new-light);
  }


  .DescriptionContainer {
    position: relative;
    margin-bottom: 24px;
    margin-top: 24px;

    .DescriptionLength {
      position: absolute;
      right: 8px;
      bottom: 21px;
      color: var(--text-03);
      @include text-14-reg;
    }

    .DescriptionLabel {
      margin-top: 24px;
    }

    .DescriptionInput {
      width: 100%;
      height: 119px;
      padding-left: 16px;
      padding-top: 20px;
      padding-right: 16px;
      background: var(--new-light);
      border-radius: 12px;
      border: 0;
      resize: none;
      @include text-14-reg;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--text-03);
      }
    }
  }

  .Label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include text-14-reg;
    letter-spacing: 0.25px;
    color: var(--text-01);
  }

  .BirthDateContainer {
    margin-top: 24px;

    .DateTimePicker {
      height: 40px;
      overflow: hidden;
      border-radius: 12px;
      width: 100%;

      input {
        width: 100%;
        margin-top: 0;
        padding: 10px 16px;
        background-color: var(--input-bg-color);
        border-radius: var(--input-border-radius);

        &::placeholder {
          color: var(--new);
        }
      }
    }
  }

  .MapDropdown {
    margin-bottom: 24px;
  }

  .Dropzone {
    width: 335px;
    height: 335px;
    filter: initial;
    margin-bottom: 24px;
  }

  .WithoutBorder {
    border: none;
  }

  .Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 335px;
    height: 335px;
    margin-bottom: 24px;
  }
}

.ModalText {
  margin-top: 16px;
  margin-bottom: 24px;
  @include text-14-reg;
  color: var(--text-01);
}