@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  width: 1096px;
  margin: 0 auto;

  .ReportHeader {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 3px;

    .Info {
      display: flex;
      margin-right: auto;

      .InfoBlock {
        display: flex;
        @include text-14-reg;
        flex-direction: column;
        color: var(--text-02);

        span {
          &:last-child {
            font-weight: 700;
            color: var(--new);
          }
        }
      }

      .InfoBlock + .InfoBlock {
        margin-left: 32px;
      }
    }

    .CloseIcon {
      margin-left: 24px;
      margin-right: 4px;
      cursor: pointer;

      rect {
        fill: var(--dark-blue);
      }
    }

  }

  .Button {
    width: 167px;
  }
}
