.TabBar{

  .TabContainer{
    display: flex;
    align-items: center;
  }

  .NavItem{
    height: 40px;
    padding: 7px 22px;
    background-color: #F5F7FA;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.6;
    color: #C1C1C6;
    cursor: pointer;

    &:first-child{
      border-radius: 8px 0 0 0;
    }
    &:last-child{
      border-radius: 0 8px 0 0;
    }

    &.Active{
      border-radius: 8px 8px 0 0;
      background-color: #FFFFFF;
      color: #3C49C6;
    }
  }

  .TabContent{
    .TabBarItem{
      display: none;
      &.Active{
        display: block;
      }
    }
  }



  .Button{
    border: none;
    outline: none;

    &:disabled {
      cursor: not-allowed;
    }
  }
}


.TabBarSecondary{
  .TabContainer{
    display: flex;
    align-items: center;
  }

  .NavItem{
    position: relative;
    height: 40px;
    padding: 7px 22px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #303141;
    cursor: pointer;

    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom:2px;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background-color: #F2F2F2;
    }

    &.Active{
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
        border-radius: 6px;
        background-color: #3C49C6;
      }
    }
  }

  .TabContent{
    .TabBarItem{
      display: none;
      &.Active{
        display: block;
      }
    }
  }

  .Button{
    border: none;
    outline: none;
    background-color: transparent;
  }
}



