@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 280px 32px 232px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "ID . Title";

  .ID {
    grid-area: ID;
  }

  .Title {
    grid-area: Title;
  }
}

.Item {
  grid-template-columns: 280px 32px 464px 64px auto;
  grid-template-rows: auto;
  grid-template-areas:
       "ID . Title . More";


  &:last-child {
    padding-bottom: 0;
  }

  .ID {
    grid-area: ID;
  }

  .Title {
    grid-area: Title;
  }

  .More {
    grid-area: More;
  }
}