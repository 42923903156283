@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 492px 32px 200px 32px  auto;
  grid-template-rows: auto;
  grid-template-areas:
      "name . ownerName . hasDevice";

  .Name {
    grid-area: name;
  }

  .OwnerName {
    grid-area: ownerName;
  }

  .HasDevice {
    grid-area: hasDevice;
  }
}

.BusinessItem {
  grid-template-columns: 40px 16px 436px 32px 200px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "logo . name . ownerName . hasDevice";

   &:last-child {
    padding-bottom: 0;
    border: none;
  }

  .Logo {
    grid-area: logo;
    width: 40px;
    height: 40px;
    object-fit: cover;
    filter: drop-shadow(0px 2px 16px rgba(48, 49, 65, 0.08));
    border-radius: 50%;

    img {
      width: 40px;
      height: 40px;
    }

    div {
      width: 40px;
      height: 40px;
    }
  }

  .Name {
    grid-area: name;
    @include text-16-medium;
    color: var(--text-01);
  }

  .OwnerName {
    grid-area: ownerName;
    @include text-14-reg;
    color: var(--text-01);
  }

  .HasDevice {
    grid-area: hasDevice;
    display: flex;
    align-items: center;

    svg {
      margin-left: 22px;
      cursor: pointer;

      &:hover {
        path {
          fill: var(--brand-01);
        }
      }
    }
  }
}

.Banned {
  .Logo {
    opacity: 0.55;
  }

  .Name {
    opacity: 0.55;
  }

  .OwnerName {
    opacity: 0.55;
  }
}