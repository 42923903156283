.NewAreaButton {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #303141;
  border: none;
  outline: none;
  background-color: transparent;
  text-decoration-line: underline;
  cursor: pointer;
}

.RemoveAreaButton {
  position: relative;
  padding: 6px 20px 6px 12px;
  margin-right: 8px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  background: #303141;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    rect {
      fill: #FFFFFF;
    }
  }
}