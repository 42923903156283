@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 689px;
    margin-right: 24px;
  }

  .PreviewContainer {
    width: 383px;
  }

  .UserContainer {
    margin-top: 24px;
  }

  .BusinessItemContainer {
    margin-top: 32px;
  }

  .AddUserButton {
    margin-top: 24px;
  }

  .DescriptionContainer {
    position: relative;
    margin-bottom: 24px;
    margin-top: 24px;

    .DescriptionLength {
      position: absolute;
      right: 8px;
      bottom: 21px;
      color: var(--text-03);
      @include text-14-reg;
    }

    .DescriptionLabel {
      margin-top: 24px;
    }

    .DescriptionInput {
      width: 100%;
      height: 119px;
      padding-left: 16px;
      padding-top: 20px;
      padding-right: 16px;
      background: var(--new-light);
      border-radius: 12px;
      border: 0;
      resize: none;
      @include text-14-reg;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--text-03);
      }
    }
  }

  .Label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include text-14-reg;
    letter-spacing: 0.25px;
    color: var(--text-01);
  }

  .BirthDateContainer {
    margin-top: 24px;

    .DateTimePicker {
      height: 40px;
      overflow: hidden;
      border-radius: 12px;
      width: 100%;

      input {
        width: 100%;
        margin-top: 0;
        padding: 10px 16px;

        &::placeholder {
          color: var(--new);
        }
      }
    }
  }

  .DropZone {
    width: 335px;
    height: 188px;
    filter: initial;
    margin-bottom: 24px;
  }

  .WithoutBorder {
    border: none;
  }

  .Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 335px;
    height: 188px;
    margin-bottom: 24px;
  }
}