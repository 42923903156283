@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  border: 1px solid #E4E6F7;
  border-radius: 8px;

  :global {
    .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
      stroke-opacity: 0;
    }
  }

  .Title {
    padding-top: 16px;
    padding-bottom: 12px;
    padding-left: 16px;
    @include text-16-bold;
    color: var(--text-01);
    border-bottom: 1px solid #F0F1FB;
  }

  .ChartContainer {
    padding-right: 12px;
  }
}

.CustomTooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px;
  background: var(--dark-blue);
  color: var(--white);
  border-radius: 12px;
  @include roboto;
  font-style: normal;

  .Date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  .Value {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }
}