.mentions {
  width: 100%;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 9px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  border-radius: var(--input-border-radius);
  background-color: var(--input-bg-color);
  font-family: Roboto, sans-serif;
  font-size: 14px;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 14px 16px;
  min-height: 63px;
}
.mentions--multiLine .mentions__input {
  border: none;
  padding: 14px 16px;
  outline: 0;
}

.mentions__suggestions__list {
  max-height: 500px;
  overflow-y: auto;
  min-width: 286px;
  background-color: white;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #303141;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  border-radius: 2px 2px 12px 12px;
}

.mentions__suggestions__customItem{
  display: flex;
  align-items: center;

  &>span{
    margin-left: 8px;
  }
}

.mentions__suggestions__item {
  padding: 6px 16px;
}

.mentions__suggestions__item--focused {
  background-color: #F0F1FB;
}


.Highlights{
  color: #3C49C6;
}

.customMention{
  color: #3C49C6;
  font-weight: bold;
}


.mentions__mention {
  position: relative;
  top: -1px;
  left: -1px;
  z-index: 1;
  color: blue;
  pointer-events: none;
}