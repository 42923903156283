.Container {
  position: relative;

  .DropdownInputContainer {
    position: relative;
    width: 160px;
    margin: 0;

    .DropdownInput {
      display: block;
      width: 100%;
      height: 40px;
      padding: 10px 40px 10px 16px;
      background: #FFFFFF;
      border: none;
      outline: none;
      border-radius: 12px;
    }

    .DropdownInputOpen{
      border-radius: 12px 12px 12px 0;
    }

    .DropdownInputText {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #5E5F70;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .DropdownInputPlaceholder {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #999;
    }

    img {
      position: absolute;
      top: 12px;
      right: 15px;
      cursor: url(../../../../shared/icons/cursor.svg) 0 0, pointer;
    }

  }

  .OptionsContainer {
    position: absolute;
    width: 166px;
    max-height: 240px;
    left: 0;
    top: 40px;
    background: #FFFFFF;
    box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
    border-radius: 2px 12px 12px 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 5;

    :hover {
      background-color: #F0F1FB;
      cursor: url(../../../../shared/icons/cursor.svg) 0 0, pointer;
    }

    .Option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 16px;
      width: 166px;
      height: 48px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      .OptionTitle {
        color: #303141;
      }

      .OptionAmountOfPolls {
        color: #C1C1C6;

      }

    }
  }
}
