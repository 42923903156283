@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.ButtonCommon button{
  padding: 13px 20px;
  border-radius: 48px;
  height: 50px;
  border: 0;
  cursor: pointer;
  outline: none;
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0075em;
}


.Button button {
  box-shadow: 0 14px 14px rgba(239, 51, 64, 0.16);
  background-color: #ef3340;
  color: #ffffff;
}

.SecondaryButton button{
  padding: 13px 28px;
  border-radius: 48px;
  height: 50px;
  background-color: #FCE7EA;
  color: #EF3340;
}

.Container {
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}

.Text {
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  letter-spacing: 0.0075em;
  color: #ef3340;
}

.Text:hover {
  color: #cd0818;
}


.Button_v2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 28px;
  border-radius: 48px;
  border: 0;
  cursor: pointer;
  outline: none;
  @include text-16-medium;
  box-shadow: 0 14px 14px rgba(239, 51, 64, 0.16);
  background-color: var(--brand-01);
  color: var(--white);

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

.SecondaryButton_v2 {
  background-color: var(--light-red);
  color: var(--brand-01);
}

.TextButton {
  padding: 0;
  background-color: transparent;
  color: var(--brand-01);
  box-shadow: none;
}

.NotRounded {
  @include text-14-bold;
  border-radius: 6px;
}

.PlusButton {
  color: var(--complementary-02);
}

.PlusIcon {
  margin-right: 9px;
}

.BackIcon {
  margin-right: 12px;
}

