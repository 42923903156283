@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.OverviewContainer {
  padding: 24px 16px;


  .About {
    margin-bottom: 12px;
    max-height: 120px;
    overflow-y: scroll;

    .Title {
      margin-bottom: 12px;
      @include text-18-bold;
      color: var(--text-01);
    }

    .Description {
      @include text-14-reg;
      color: var(--text-01);
    }
  }

  .BusinessInfo {
    display: flex;
    align-items: center;
    padding: 8px 0;
    @include text-16-reg;
    color: var(--text-01);

    .Icon {
      margin-right: 16px;
    }

    .Button {
      margin-left: auto;
      color: var(--complementary-02);
      text-decoration: none;
    }
  }
}

.MapContainer {
  height: 400px;
  width: 100%;
}