.DropDown {
  :global {
    .InputContainer{
      width: 160px;
      .Input {
        height: 40px;
        background-color: var(--white);
        cursor: pointer;

        &::placeholder {
          color: var(--new);
        }
      }
      .OptionsContainer {
        top: 40px;
        overflow: hidden;
      }
    }
  }
}