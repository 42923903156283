@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
  width: 173px;
  height: 173px;
  border-radius: 8px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    height: 150px !important;
  }

  .TimesIcon {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--white);
    opacity: 0.8;
    cursor: pointer;
    z-index: 5;

    &:hover {
      opacity: 1;

      rect {
        fill: var(--brand-01)
      }
    }

    rect {
      fill: var(--text-01)
    }
  }

  .Media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}