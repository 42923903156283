@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Sidebar {
  position: relative;
  width: 192px;
  padding-left: 24px;
  flex-shrink: 0;


  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    background-color: var(--white);
    z-index: 5;

    &.Active {
      display: block;
      width: 30%;
      height: 100vh;
    }
  }

  @include media-breakpoint-down(sm) {
    &.Active {
      width: 100%;
    }
  }
}


.Burger {
  position: fixed;
  left: 16px;
  bottom: 34px;
  z-index: 10;
}