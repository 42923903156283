.Container {
  margin-right: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 24px 0 24px 24px;
  border: 1px solid rgba(48, 49, 65, 0.25);
  border-radius: 8px;
}

.TitleContainer {
  height: 24px;
  margin: 0 0 20px 0;
}

.IsEnabledCheckbox {
  width: 24px;
  height: 24px;

  cursor: pointer;
}

.TitleLabel {
  margin-left: 10px;
  vertical-align: top;

  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #303141;

  cursor: pointer;
}

.TimeIntervalsList {
  margin: 0;
  padding: 0;
}
