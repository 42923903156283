@import "src/submodules/naoo-web-components/styles/mixins";
.Container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  cursor: pointer;

  .RestrictionLabel {
    @include text-14-reg;
  }

  &+& {
    margin-top: 12px ;
  }
}