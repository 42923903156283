@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.CreateOfferInfo {
  margin-bottom: 50px;
  padding: 30px 24px;
  background-color: white;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;
}

.Title {
  display: block;
  height: 24px;
  @include text-20-bold;
  color: var(--text-01);
}

.OfferTitleLabel {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
}

.OfferTitleInputContainer {
  position: relative;
  width: 100%;
}

.OfferTitleInputContainer .OfferTitleInputLengthCounter {
  position: absolute;
  right: 16px;
  top: 16px;
}

.OfferTitleInput {
  width: 100%;
  height: 48px;
  padding-right: 56px;
  padding-left: 16px;
  background-color: var(--input-bg-color);
  border-radius: var(--input-border-radius);
  border: 0;
  @include text-14-reg;
}

.OfferTitleInputLengthCounter {
  width: 40px;
  height: 16px;
  @include text-14-reg;
  text-align: right;
  color: rgba(51, 51, 51, 0.5);
}

.OfferDescriptionLabel {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
}

.OfferDescriptionInput {
  width: 100%;
  height: 119px;
  padding-left: 16px;
  padding-top: 20px;
  background-color: var(--input-bg-color);
  border-radius: var(--input-border-radius);
  border: 0;
  resize: none;
  @include text-14-reg;
}

.OfferImageAreaTitle {
  display: block;
  margin-top: 40px;
  margin-bottom: 20px;
  @include text-20-bold;
  color: var(--text-01);
}


.CreateOfferInfo .Dropzone {
  width: 285px;
  height: 190px;
  margin-right: 24px;
}


.PendingMediaInfo {
  width: 120px;
  height: 18px;
}

.PendingMediaInfo button {
  border: 0;
  background-color: transparent;
  @include text-14-reg;
  align-items: center;
  cursor: pointer;
  color: var(--support-error);
}

.ImgInfoContainer {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    align-items: flex-start;
    flex-direction: column;
  }
}
