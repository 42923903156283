@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Title {
  display: block;
  margin-top: 16px;
  margin-bottom: 40px;
  @include text-hero;
  color: var(--text-01);
}

.SubTitle {
  display: block;
  height: 24px;
  margin-bottom: 20px;
  @include text-20-bold;
  color: var(--text-01);
}

.Section {
  margin-bottom: 50px;
  padding: 32px 24px;
  background-color: white;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;
}

.NameContainer {
  position: relative;
}

.NameLength {
  position: absolute;
  right: 8px;
  top: 21px;
  color: var(--text-03);
}

.Input {
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  margin-top: 8px;
  margin-bottom: 24px;
  background: var(--input-bg-color);
  border: none;
  border-radius: var(--input-border-radius);
  @include text-14-reg;
  color: var(--text-01);
}

.InputError {
  border: 1px solid var(--support-error);
}

.DescriptionContainer {
  position: relative;
  margin-bottom: 40px;
}

.DescriptionLength {
  position: absolute;
  right: 8px;
  bottom: 21px;
  color: var(--text-03);
}

.BusinessDescriptionLabel {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
}

.BusinessDescriptionInput {
  width: 100%;
  height: 119px;
  padding-left: 16px;
  padding-top: 20px;
  padding-right: 16px;
  background: var(--input-bg-color);
  border-radius: var(--input-border-radius);
  border: 0;
  resize: none;
  @include text-14-reg;
}

.DropzoneContainer {
  position: relative;
  width: fit-content;
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 45px;
  border-radius: 50%;
  outline: none;
}

.DropzoneActive {
  background-color: rgba(200, 200, 200, 0.7);
}

.DropzoneContainer {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.DropzoneContainer .LogoDropZone {
  width: 200px;
  height: 200px;
  filter: none;
  border-radius: 50%;
}

.DropzoneContainer .CoverDropZone {
  width: 285px;
  height: 190px;
  border-radius: 8px;
}

.Gallery {
  max-width: 740px;
}

.PendingMediaInfo {
  margin-left: 24px;
  display: flex;
  gap: 6px;

  @include media-breakpoint-down(sm) {
    margin-top: 8px;
  }
}

.PendingMediaInfo button {
  border: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
  @include text-14-reg;
  align-items: center;
  cursor: pointer;
  color: var(--support-error);
  outline: none;
}

.LogoName {
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
}

.MapContainer {
  width: 100%;
  height: 300px;
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: fit-content;
  cursor: pointer;
}

.CheckboxLabel {
  @include text-16-reg;
  color: var(--text-01);
  margin-left: 12px;
}