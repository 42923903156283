:root{
  --text-01:#303141;
  --new:#5E5F70;
  --text-02:#9798A0;
  --text-03:#C1C1C6;
  --ui-02:#F2F2F2;
  --new-light:#F5F7FA;
  --selection:#F0F1FB;
  --bg:#F8FAFC;
  --white: #FFFFFF;
  --black:#000000;
  --light-red:#FCE7EA;
  --brand-01:#EF3340;
  --support-success: #1DBF7F;
  --support-error:#FF5722;
  --complementary-01:#37B9AA;
  --complementary-02:#3C49C6;
  --complementary-03:#A076BA;
  --progress:#E4E6F7;
  --dark-blue:#1F2A99;


  --input-bg-color: var(--new-light);
  --input-border-radius: 4px;
  /*--shedule-bg-color:#F5F7FA;*/
  /*--shedule-bg-input-color:#FFFFFF;*/
  /*--shedule-border-radius:12px;*/
  --dropzone-bg-color:var(--new-light);
  --dropzone-hover-bg-color: var(--new-light);
  --dropzone-border: 1px dashed #C1C1C6  ;
  --dropzone-hover-border: 1px dashed #C1C1C6 ;
}

:root[data-theme="LIGHT"] {
  --background-color: #f8fafc;
}

:root[data-theme="DARK"] {
  --background-color: #333232;
}