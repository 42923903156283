@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.SidebarItem {
  text-align: left;
  padding: 0;
  margin-top: 30px;
  border: 0;
  text-decoration: none;
  @include text-16-medium;
  color: var(--text-01);
  cursor: pointer;
  width: fit-content;
}

.SidebarItemDisabled {
  pointer-events: none;
  cursor: default;
  color: var(--text-01);
  opacity: 0.25;
  text-align: left;
  padding: 0;
  margin-top: 30px;
  background: rgba(0, 0, 0, 0);
  border: 0;
  text-decoration: none;
}

.active{
   color: #ef3340;
}