@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";


.Header {
  grid-template-columns: 284px 16px 160px 16px 103px 16px 69px 16px 33px 16px 40px 16px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "post . originAccount . publishDate . comments . likes . types . featured";

  .Post {
    grid-area: post;
  }

  .OriginAccount {
    grid-area: originAccount;
  }

  .PublishDate {
    grid-area: publishDate;
    display: flex;
    align-items: center;
  }

  .Comments {
    grid-area: comments;
  }

  .Likes {
    grid-area: likes;
  }

  .Types {
    grid-area: types;
  }

  .Featured {
    grid-area: featured;
  }

}

.PostItem {
  grid-template-columns: 45px 16px 223px 16px 36px 8px 116px 16px 103px 16px 69px 16px 33px 16px 40px 16px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "logo . post . accountLogo . account . publishDate . comments . likes . types . featured . more";

  &:hover {
    background-color: var(--bg);
    cursor: pointer;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .Logo {
    grid-area: logo;
    width: 45px;
    height: 56px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }

  .Post {
    grid-area: post;
  }

  .AccountLogo {
    grid-area: accountLogo;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }

  .Account {
    grid-area: account;
  }

  .PublishDate {
    grid-area: publishDate;

    span {
      display: inline-block;
      width: 80px;
    }
  }

  .Comments {
    grid-area: comments;
  }

  .Likes {
    grid-area: likes;
  }

  .Types {
    grid-area: types;
  }

  .Featured {
    grid-area: featured;
  }

  .More {
    grid-area: more;
    justify-self: end;
  }
}
