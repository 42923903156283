.PresetsDropdown {
  display: block;
  position: absolute;
  width: 277px;
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

.PresetsDropdown li {
  background: var(--input-bg-color);
}

.PresetsDropdown span {
  margin-left: 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 38px;
  align-items: center;
  letter-spacing: 0.25px;
  cursor: pointer;
  color: #303141;
}
