.Container {
  position: relative;
  display: inline-block;
  width: 277px;
  height: 76px;
  margin-right: 20px;
}

.Title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #303141;
}

.Value {
  width: 277px;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #303141;
  background: #f4f4f4;
  border-radius: 4px;

  cursor: pointer;
}

.DatePicker input{
  width: 100%;
  background-color: var(--input-bg-color);
}
