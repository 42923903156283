@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.OverlayContainer {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 70px;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 15;
  overflow-y: auto;
}
.Container{
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 70px;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
  z-index: 16;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
    z-index: 100;
  }

  .CloseIcon {
    position: absolute;
    right: 35px;
    top: 30px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      display: none;
    }

    svg {
      rect {
        fill: var(--dark-blue);
      }
    }
  }
}