@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Comment {
  display: flex;
  width: 100%;
  margin-top: 11px;
  height: fit-content;
  padding: 8px 0 8px 8px;

  .Logo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .UserNameAndInfo {
    display: flex;
    align-items: center;
    width: 97%;
    margin-bottom: 3px;
    @include text-14-reg-jost;
    color: var(--text-02);

    .Name {
      margin-right: 12px;
      @include text-14-reg;
      color: var(--text-01);
    }

    .Time {
      margin-right: 5px;
    }

    .More {
      margin-left: auto;
    }
  }

  .Text {
    width: 100%;
   @include text-14-reg;
    color: var(--text-01);
  }

  .Replies {
    .Logo {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }

}

.Reported {
  background-color: var(--light-red);
  border-radius: 8px;
}