@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container{
  position: relative;
  &+&{
    margin-top: 16px;
  }

  :global{
    .SelectedBusiness{
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 36px 14px 16px;
      background-color: var(--new-light);
      border-radius: 12px;
      @include text-14-reg;

      .Avatar{
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 8px;
      }

      .SelectedBusinessName{
        margin-right: 8px;
        color:var(--text-01);
      }
      .SelectedBusinessID{
        color:var(--text-03);
      }

      .CloseIcon{
        position: absolute;
        right: 16px;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: .3s;
        &:hover{
          background-color: var(--white);
          cursor: pointer;
          svg{
            rect{
              fill: var(--brand-01);
            }
          }
        }
      }
    }

    .InputDropdownOpen{
      border-radius: 12px 12px 12px 2px !important;
    }

    .InputContainer{

      .Input{
        width: 100%;
        padding: 14px 36px 14px 16px;
        background-color: #F5F7FA;
        border: none;
        border-radius: 12px 12px 12px 12px;
        @include text-14-reg;
        &::placeholder{
          color:var(--text-03);
        }
        &:focus{
          outline: none;
        }


      }

      .Icon{
        position: absolute;
        right: 14px;
        top: 45px;
      }
    }

    .ItemsContainer{
      position: absolute;
      width: 453px;
      max-height: 240px;
      left: 0;
      top: 76px;
      background-color: var(--white);
      box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
      border-radius: 2px 2px 12px 12px;
      overflow-y: scroll;
      z-index: 1;

      .BusinessItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
       @include text-14-reg;
        &:hover{
          background-color: var(--selection);
          cursor: pointer;
        }

        .BusinessName{
          color: var(--text-01);
        }
        .BusinessID{
          color: var(--text-03);
        }
      }
    }
  }
}
.Label{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
 @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
}
