@import 'variables';
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}


@mixin roboto{
  font-family: 'Roboto', sans-serif;
}

@mixin jost{
  font-family: 'Jost', sans-serif;
}

@mixin text-hero {
  @include jost;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 34px;
}

@mixin text-32-bold {
  @include jost;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
}

@mixin  text-24-bold {
  @include roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

@mixin  text-20-bold {
  @include jost;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@mixin text-20-medium {
  @include jost;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

@mixin text-18-bold {
  @include jost;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
}

@mixin text-16-bold{
  @include roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
}

@mixin text-16-bold-jost{
  @include jost;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

@mixin text-16-medium{
  @include jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.0075em;
}

@mixin text-16-reg{
  @include roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-14-bold{
  @include roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

@mixin text-14-medium-jost{
  @include jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

@mixin text-14-reg {
  @include roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-14-reg-jost{
  @include jost;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

@mixin text-12-reg{
  @include roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}