.lightBlurPreloader{
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 100;
}

.spinnerSize{
  width: 50px;
  height: 50px;
}

.paginationSpinnerSize{
  margin-top: 16px !important;
  width: 25px !important;
  height: 25px !important;
}