@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Options {
  padding: 0;
  margin-bottom: 40px;
  margin-top: 0;
  display: flex;
  list-style-type: none;
  align-items: center;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.Options li {
  cursor: pointer;
}

.Options li:first-child {
  margin-right: 84px;
}

.OptionRadioButtonIcon {
  height: 25px;
  width: 25px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.OptionText {
  margin: auto 16px;
  @include text-16-reg;
  align-items: center;
  vertical-align: middle;
  color: var(--text-01);
}

.CustomMaxClaimsInput {
  width: 86px;
  background-color: var(--input-bg-color);
  border: 0;
  border-radius: var(--input-border-radius);
  padding: 14px 12px 14px 16px;
  @include text-14-reg;
  align-items: center;
  letter-spacing: 0.25px;
  color: var(--text-01);
}