section {
    flex: 1 1 25%;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(0,0,0,0.3);
}

.skCircleBounce {
    width: 4em;
    height: 4em;
    position: relative;
    margin: auto;
}
.skCircleBounce .skChild {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.skCircleBounce .skChild:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #EF3340;
    border-radius: 100%;
    animation: skCircleBounce-delay 1.2s infinite ease-in-out both;
}
.skCircleBounce .skCircle2 {
    transform: rotate(30deg);
}
.skCircleBounce .skCircle3 {
    transform: rotate(60deg);
}
.skCircleBounce .skCircle4 {
    transform: rotate(90deg);
}
.skCircleBounce .skCircle5 {
    transform: rotate(120deg);
}
.skCircleBounce .skCircle6 {
    transform: rotate(150deg);
}
.skCircleBounce .skCircle7 {
    transform: rotate(180deg);
}
.skCircleBounce .skCircle8 {
    transform: rotate(210deg);
}
.skCircleBounce .skCircle9 {
    transform: rotate(240deg);
}
.skCircleBounce .skCircle10 {
    transform: rotate(270deg);
}
.skCircleBounce .skCircle11 {
    transform: rotate(300deg);
}
.skCircleBounce .skCircle12 {
    transform: rotate(330deg);
}
.skCircleBounce .skCircle2:before {
    animation-delay: -1.1s;
}
.skCircleBounce .skCircle3:before {
    animation-delay: -1s;
}
.skCircleBounce .skCircle4:before {
    animation-delay: -0.9s;
}
.skCircleBounce .skCircle5:before {
    animation-delay: -0.8s;
}
.skCircleBounce .skCircle6:before {
    animation-delay: -0.7s;
}
.skCircleBounce .skCircle7:before {
    animation-delay: -0.6s;
}
.skCircleBounce .skCircle8:before {
    animation-delay: -0.5s;
}
.skCircleBounce .skCircle9:before {
    animation-delay: -0.4s;
}
.skCircleBounce .skCircle10:before {
    animation-delay: -0.3s;
}
.skCircleBounce .skCircle11:before {
    animation-delay: -0.2s;
}
.skCircleBounce .skCircle12:before {
    animation-delay: -0.1s;
}
@keyframes skCircleBounce-delay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
