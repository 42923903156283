@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";


.ItemsContainer {
  padding: 32px 24px;
  width: 1088px;
  background: var(--white);
  border-radius: 0 8px 8px 8px;

  .ItemsHeader {
    margin-bottom: 16px;

    .Title {
      @include text-20-bold;
      color: var(--text-01);
      margin-right: 24px;
    }
  }

  .VoucherTableHeader {
    display: grid;
    grid-template-columns: 480px 32px 480px;
    grid-template-rows: auto;
    grid-template-areas:
      "Title . Description";
    align-items: center;
    @include text-14-reg;
    color: var(--new);

    .Title {
      grid-area: Title;
    }

    .Description {
      grid-area: Description;
    }
  }

  .VoucherTableItem {
    display: grid;
    grid-template-columns: 40px 16px 424px 32px 480px 38px auto;
    grid-template-rows: auto;
    grid-template-areas:
      "Logo . Title . Description . More";
    align-items: center;
    @include text-14-reg;
    color: var(--text-01);
    border-bottom: 1px solid var(--selection);
    padding-top: 16px;
    padding-bottom: 16px;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }

    .Logo {
      grid-area: Logo;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
    }

    .Title {
      grid-area: Title;
      font-weight: bold;
      line-height: 18px;
    }

    .Description {
      grid-area: Description;
    }

    .More {
      grid-area: More;
    }
  }

  .ItemsFooter {
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
      @include text-16-reg;
      background-color: var(--white);
      color: var(--complementary-02);
      margin-top: 16px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}

.TimepickerBgc {
  background-color: var(--white) !important;
}