.Container {
  display: flex;
  height: 100vh;

  .ChannelListWrap {
    max-width: 320px;
  }

  .ConversationWrap {
    flex: 1 1;

    :global {
      .sendbird-conversation {
        border-top: none;
      }
    }
  }

  .SettingsWrap {

  }

  :global {
    .sendbird-channel-preview {
      .sendbird-channel-preview__content {
        .sendbird-channel-preview__content__upper {
          align-items: center;

          .sendbird-channel-preview__content__upper__header {
            align-items: center;

            .sendbird-channel-preview__content__upper__header__frozen-icon {
              .sendbird-icon-freeze {
                width: 15px !important;
                height: 15px !important;
              }
            }
          }
        }
      }
    }

    .sendbird-badge {
      display: flex;
      align-items: center;
      justify-content: center;

      .sendbird-badge__text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
      margin-top: 0;
    }

    .sendbird-message-input .sendbird-message-input--textarea {
      border-radius: 12px;
      overflow-y: auto;
    }
  }
}

:global {
  :root {
    --sendbird-light-primary-100: #FDE7E8;
    --sendbird-light-primary-300: #EF3340;
    --sendbird-light-information-100: #8FD8CF;
    --sendbird-light-background-100: #F5F7FA;
    --sendbird-light-primary-400: #CF002A;
    --sendbird-light-primary-500: #AF0015;

    --sendbird-font-family-default: "Jost", sans-serif;
  }

  .sendbird-theme--light .sendbird-checkbox--checkmark {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .sendbird-theme--light .sendbird-checkbox input:focus ~ .sendbird-checkbox--checkmark {
    box-shadow: none;
  }

  .sendbird-theme--light .sendbird-checkbox .sendbird-checkbox--checkmark:after {
    width: 14px;
    height: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../../submodules/naoo-web-components/Shared/UI/assets/check.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
  }

  .sendbird-theme--light .sendbird-checkbox--checkmark {
    border-radius: 4px;
  }

  .sendbird-theme--light .sendbird-icon-toggle-on {
    svg {
      g {
        circle {
          fill: #37B9AA !important;
          box-shadow: 0 2.66667px 5.33334px rgba(48, 49, 65, 0.12);
        }

        rect {
          fill: rgba(55, 185, 170, 0.2) !important;
        }

        path {
          display: none;
        }
      }
    }
  }

  .sendbird-theme--light .sendbird-icon-toggle-off {
    svg {
      g {
        circle {
          box-shadow: 0 2.66667px 5.33334px rgba(48, 49, 65, 0.12);
        }

        rect {
          fill: rgba(193, 193, 198, 0.2) !important;
        }

        path {
          display: none;
        }
      }
    }
  }

  .sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    box-shadow: none;
    overflow-y: initial;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .sendbird-theme--light .sendbird-message-input__edit {
    background-color: #F5F7FA
  }

  .sendbird-message-input .sendbird-message-input--attach {
    svg {
      path {
        fill: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }

  .sendbird-theme--light .sendbird-button--secondary {
    border: 1px solid var(--sendbird-light-primary-300) !important;
  }

  .sendbird-theme--light .sendbird-button--secondary .sendbird-button__text {
    color: var(--sendbird-light-primary-300) !important;

    .sendbird-label--color-onbackground-1 {
      color: var(--sendbird-light-primary-300) !important;
    }
  }

  .sendbird-theme--light .sendbird-modal__content {
    border-radius: 24px;
    box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  }

  .sendbird-theme--light .sendbird-input .sendbird-input__input {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: none;
    overflow-y: initial;

    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}