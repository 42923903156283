:root{
    --input-bg-color: #F5F7FA;
    --input-border-radius: 12px;
    --shedule-bg-color:#F5F7FA;
    --shedule-bg-input-color:#FFFFFF;
    --shedule-border-radius:12px;
    --dropzone-bg-color:#F8FAFC;
    --dropzone-hover-bg-color: #F8FAFC;
    --dropzone-border: 1px dashed #C1C1C6  ;
    --dropzone-hover-border: 1px dashed #C1C1C6 ;
}
