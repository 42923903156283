@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.NaooBusinessLogo {
  display: block;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
    margin: 20px 32px 0;
  }
}

.AuthForm {
  width: 438px;
  margin: 40px auto auto;
  padding: 40px 80px 32px;
  background-color: var(--white);
  border: 0;
  border-radius: 6px;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);

  .FormTitle {
    display: inline-block;
    width: 278px;
    margin-bottom: 24px;
    @include text-24-bold;
    color: var(--text-01);

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .Input {
    margin-bottom: 24px;
  }

  .ForgotPasswordLink {
    @include text-16-reg;
    color: var(--complementary-02);
    text-decoration: none;
    text-align: center;
    display: block;
    margin-top: 24px;
    &:hover{
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(sm) {
    min-width: 280px;
    width: 100%;
    height: initial;
    padding: 32px;
    box-shadow: none;
    margin: 35% 0 0;
  }
}