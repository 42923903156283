.DateTimePickerContainer{
  width: fit-content;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  border-radius:12px;

 :global{
   .react-datepicker{
     box-shadow: none;
   }
 }
}

.DateTimePickerInput{
  padding: 14px 16px;
  background-color: #FFFFFF;
  border: none;
  border-radius: var(--input-border-radius);
  outline: none;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  caret-color: transparent;
  cursor: pointer;
}

.TimePickersContainer{
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;

  .TimePicker{
    width: 153px;
  }
  .TimePicker + .TimePicker{
    margin-left: 16px;
  }
}

.ButtonsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  div+div{
    margin-left: 16px;
  }

  .Button{
    width: 153px;
  }

}