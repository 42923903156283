@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 681px;
    margin-right: 24px;
  }

  .PreviewContainer {
    width: 383px;
  }

  .Preview {
    position: relative;
    z-index: 1;
  }

  .DisablePreview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#FFFFFF, .8);
    cursor: not-allowed;
    z-index: 2;
  }

  .UserContainer {
    margin-top: 24px;
  }

  .AddUserButton {
    margin-top: 24px;
    @include text-16-reg;
    color: var(--complementary-02);
  }

  .Icon {
    margin-right: 9px;
  }

  .Buttons {
    display: flex;
    align-items: center;

    .Left {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .Right {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .Subtitle {
      margin-right: 40px;
      @include text-14-reg;
      color: var(--text-01);
    }
  }

  .Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 335px;
    height: 188px;
    margin-bottom: 24px;
  }

  .DropZone {
    width: 335px;
    height: 188px;
    filter: none;
    margin-bottom: 24px;
  }

  .WithoutBorder {
    border: none;
  }

  .Title {
    margin-bottom: 24px;
    @include text-20-bold;
    color: var(--text-01);
  }

  .DescriptionContainer {
    position: relative;
    margin-bottom: 40px;

    .DescriptionLength {
      position: absolute;
      right: 8px;
      bottom: 21px;
      color: var(--text-03);
      @include text-14-reg;
    }

    .DescriptionLabel {
      margin-top: 24px;
    }

    .DescriptionInput {
      width: 100%;
      height: 119px;
      padding-left: 16px;
      padding-top: 20px;
      padding-right: 16px;
      background: var(--new-light);
      border-radius: 12px;
      border: 0;
      resize: none;
      @include text-14-reg;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--text-03);
      }
    }
  }

  .Label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include text-14-reg;
    letter-spacing: 0.25px;
    color: var(--text-01);
  }

  .PointsContainer {
    position: relative;

    svg {
      position: absolute;
      right: 16px;
      top: 45px;
    }
  }
}