.DateTimePickerInput {
  position: relative;
  padding: 14px 16px;
  background-color: #fff;
  border: none;
  border-radius: 12px;
  outline: none;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  caret-color: transparent;
  cursor: pointer;
}

.DatepickerCalendar {
  position: relative;
  padding: 12px 16px;
  border: none;
  box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
  border-radius: 12px 0 12px 12px;

  :global {
    .react-datepicker {
      max-width: 354px;
      min-width: 310px;
      min-height: 305px;

    }

    .react-datepicker__header {
      padding: 0;
      background-color: #fff;
      border: none;
      position: static;
    }

    .react-datepicker__current-month {
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }

    .react-datepicker__month-container {
      float: initial;
    }

    .react-datepicker__day-name {
      width: 44px;
      height: 40px;
      margin: 0;
      box-sizing: content-box;
      vertical-align: middle;

      &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    .react-datepicker__week {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .react-datepicker__week + .react-datepicker__week {
      margin-top: 4px;
    }

    .react-datepicker__day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 40px;
      margin: 0;
      box-sizing: content-box;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #5E5F70;

      span {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        line-height: 40px;
      }
    }


    .react-datepicker__day-name {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */

      text-align: center;

      /* text-02 */

      color: #9798A0;
    }

    .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
      border-radius: 0;
      background-color: #fff;

      span {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      border-radius: 0;
      background-color: #E4E6F7;
      color: #303141;

      &:hover {
        background-color: #E4E6F7;

        span {
          background-color: #E4E6F7;
        }
      }
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      color: black;
    }


    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
      background-color: #E4E6F7;
    }

    .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
      color: #3f51b5;
      font-weight: 600;
    }

    .react-datepicker__day--in-range {
      border-radius: 0;

      span {
        position: relative;
        z-index: 2;
      }
    }

    .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start, .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
      span {
        background-color: #3C49C6;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
      }
    }

    .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start {
      border-radius: 50% 0 0 50% !important;
      background-color: #E4E6F7 !important;

      &:hover {
        span {
          background-color: #3C49C6;
        }
      }

      & ~ .react-datepicker__day--in-selecting {
        border-radius: 50% 0 0 50% !important;
      }

      span {
        transform: scale(1.1);
      }
    }

    .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
      border-radius: 0 50% 50% 0 !important;

      span {
        transform: scale(1.1);
      }

      &:hover {
        span {
          background-color: #3C49C6;
        }
      }
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 50% !important;
    }

    .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    .react-datepicker__day--outside-month {
      color: transparent;
      pointer-events: none;
      background-color: transparent !important;
      span{
        display: none;
      }
    }

    .react-datepicker__week:first-child {
      .react-datepicker__day--in-range:first-child:not(.react-datepicker__day--outside-month) {
        background: linear-gradient(270deg, #E4E6F7 25.34%, rgba(255, 255, 255, 0) 100%);
        &:hover{
          span{
            background-color: transparent;
          }

        }
      }
    }

    .react-datepicker__week:last-child {
      .react-datepicker__day--in-range:last-child:not(.react-datepicker__day--outside-month) {
        background: linear-gradient(90deg, #E4E6F7 25.34%, rgba(255, 255, 255, 0) 100%);
        &:hover{
          span{
            background-color: transparent;
          }
        }
      }
    }

    .react-datepicker__day--outside-month + .react-datepicker__day--outside-month:after {
      background: transparent !important;
    }


    .react-datepicker__day--in-range + .react-datepicker__day--outside-month:after {
      background: linear-gradient(90deg, rgb(238, 246, 255), transparent);
    }

    .react-datepicker__day--in-selecting-range + .react-datepicker__day--outside-month:after {
      background: linear-gradient(90deg, rgb(238, 246, 255), transparent);
    }

    .react-datepicker__day--outside-month + .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--range-start) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: -40px;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #E4E6F7 25.34%, rgba(255, 255, 255, 0) 100%);
        pointer-events: none;
      }

    }

    .react-datepicker__day--outside-month + .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) {
      background: linear-gradient(90deg, #E4E6F7 25.34%, rgba(255, 255, 255, 0) 100%) !important;
    }

    .react-datepicker__day--in-range:not(.react-datepicker__day--range-end):not(.react-datepicker__day--outside-month) + .react-datepicker__day--outside-month {
      background: linear-gradient(90deg, #E4E6F7 25.34%, rgba(255, 255, 255, 0) 100%);
      border-radius: 0;

    }

    .react-datepicker__day--outside-month.react-datepicker__day--range-start {
      span {
        background-color: transparent;
      }
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
      cursor: default;
      color: #ccc;
    }

    .react-datepicker__day--selected {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    .react-datepicker__triangle {
      display: none;
    }
  }
}

.ClearIcon {
  width: 22px;
  margin: 0;
  padding: 0;
  right: 3px;

  &:hover {
    &::after {
      background-color: #ef3340;
    }
  }

  &::after {
    content: '';
    background-color: #C1C1C6;
    fill: #1F2A99;
    mask: url("../../../Shared/UI/assets/closeIcon.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    padding: 0;
  }

}