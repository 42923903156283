.Container{
  position: relative;

  .TabBar{
    width: fit-content;
    button{
      min-width: 106px;
      overflow: hidden;
    }
  }


  :global{
    .SelectedUser{
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 36px 14px 16px;
      background-color: #F5F7FA;
      border-radius: 12px;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      .Avatar{
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 8px;
      }

      .SelectedUserName{
        margin-right: 8px;
        color:#303141;
      }
      .SelectedUserID{
        color: #C1C1C6;
      }

      .CloseIcon{
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: .3s;
        &:hover{
          background-color: #fff;
          cursor: pointer;
          svg{
              rect{
                fill: #EF3340;
              }
          }
        }
      }
    }

    .InputDropdownOpen{
      border-radius: 12px 12px 12px 2px !important;
    }

    .InputContainer{
      position: relative;
      .Input{
        width: 100%;
        padding: 14px 36px 14px 16px;
        background-color: #F5F7FA;
        border: none;
        border-radius: 12px 12px 12px 12px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        &::placeholder{
          color: #C1C1C6;
        }
        &:focus{
          outline: none;
        }


      }

      .Icon{
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .Wrapper{
      position: absolute;
      left: 0;
      top: calc(100% + 8px);
      filter: drop-shadow(0px 15px 45px rgba(48, 49, 65, 0.25));
      //box-shadow: 0 15px 45px rgba(48, 49, 65, 0.25);
      z-index: 1;
    }

    .ItemsContainer{
      width: 497px;
      max-height: 240px;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 2px 2px 12px 12px;



      .UserItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        &:hover{
          background-color: #F0F1FB;
          cursor: pointer;
        }

        .Logo{
          margin-right: 8px;
        }

        .UserName{
          max-width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #303141;
        }
        .UserID{
          white-space: nowrap;
          margin-left: auto;
          color: #C1C1C6;
        }
      }

      .Rejected{
        display: flex;
        align-items: center;
        justify-content: center;

        span{
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #5E5F70;
        }

        .RejectedIcon{
          margin-right: 9px;
        }
      }
    }
  }
}
.Label{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #303141;
}
