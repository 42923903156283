@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  position: relative;
}

.Label {
  display: block;
  margin-bottom: 8px;
  @include text-14-reg;
  color: var(--text-01);
}

.Input {
  width: 100%;
  background-color: var(--input-bg-color);
  border: 0;
  border-radius: var(--input-border-radius);
  padding: 14px 16px;
  @include text-14-reg;
  letter-spacing: 0.25px;
  color: var(--text-01);
  outline: none;

    &::placeholder {
      color: var(--text-03);
    }
}

.InputError {
  border: 1px solid var(--support-error);
}

.InputLength {
  padding-right: 80px;
}

.Length {
  position: absolute;
  right: 16px;
  top: 15px;
  @include text-14-reg;
  color: var(--text-03);
}

.Container svg {
  position: absolute;
  right: 16px;
  top: 20px;
}

.Container .CopyIcon {
  cursor: pointer;
  right: 16px;
  top: calc(50% - 12px);
}

.Container .CopyIcon:hover path {
  stroke: #797a7f
}
