.PostTextPlusComments {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  padding-top: 16px;
  padding-right: 20px;
  width: 100%;

  .Header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .More {
      margin-left: auto;
    }
  }
}