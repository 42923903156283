@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: flex;
  background-color: #fff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 8px;


  .Info {
    padding: 16px 16px 16px 32px;

    .About {
      margin-bottom: 16px;

      .Title {
        margin-bottom: 12px;
        @include text-18-bold;
        color: var(--text-01);
      }

      .Text {
        @include text-14-reg;
        color: var(--text-01);
      }
    }
  }
}