.Container{
  cursor: pointer;

  input[type='checkbox']{
    display: none;
  }

  input[type='checkbox']+span{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 36px;
    border-radius: 60px;
    background-color: #F3F3F4;
    overflow: hidden;

    &::before{
      content: 'OFF';
      position: absolute;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
    }
    &::after{
      content: '';
      position: absolute;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  input[type='checkbox']:checked +span {
    background-color:  rgba(55, 185, 170, 0.2);
    &::before{
      content: 'ON';
      position: absolute;
      left: 14px;
      top: 10px;
      color: #37B9AA;
    }
    &::after{
      content: '';
      position: absolute;
      right: 6px;
      top: 7px;
      background-color: #37B9AA;
      box-shadow: 0 4px 8px rgba(48, 49, 65, 0.12);
    }
  }

  input[type='checkbox']:not(:checked) +span {

    &::before{
      content: 'OFF';
      position: absolute;
      right: 10px;
      color: #4D4D5D;
    }
    &::after{
      content: '';
      position: absolute;
      left: 6px;
      top: 7px;
      background-color: #FFFFFF;
    }
  }

}
