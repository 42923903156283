@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Header {
  grid-template-columns: 391px 32px 80px 32px 70px 32px 90px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "Title . Points . Probability . StartEnd . Winner";

  .Title {
    grid-area: Title;
  }

  .Points {
    grid-area: Points;
  }

  .Probability {
    grid-area: Probability;
  }

  .StartEnd {
    grid-area: StartEnd;
  }

  .Winner {
    grid-area: Winner;
  }
}

.CompetitionsItem {
  grid-template-columns: 100px 16px 275px 32px 80px 32px 70px 32px 90px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "Logo .Title . Points . Probability . StartEnd . Winner";

  &:hover {
    background-color: var(--bg);
    cursor: pointer;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .Logo {
    grid-area: Logo;
    width: 100px;
    height: 56px;
    border-radius: 8px;
    object-fit: cover;
  }

  .Title {
    grid-area: Title;
    @include text-14-reg;
    line-height: 18px;
  }

  .Points {
    grid-area: Points;
  }

  .Probability {
    grid-area: Probability;
  }

  .StartEnd {
    grid-area: StartEnd;
  }

  .Winner {
    grid-area: Winner;
  }
}

.Button {
  padding: 13px 23px;
}