.UserFilter {
  width: 240px;

  & > span {
    display: none;
  }

  :global {
    .InputContainer {
      .Input {
        height: 40px;
        background-color: var(--white);
        cursor: pointer;

        &::placeholder {
          color: var(--new);
        }
      }
    }

    .SelectedUser {
      background-color: var(--white);

      .SelectedUserID {
        display: none;
      }

      .SelectedUserName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ItemsContainer {
      top: 48px
    }

    .Icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}