@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.UserDropDown {
  flex-grow: 1;
}

.DateTimePicker {
  height: 40px;
  flex-shrink: 0;

  input {
    height: 40px;
  }
}

.SearchContainer {
  margin-bottom: 16px;
}

.Header {
  grid-template-columns: 190px 32px 46px 32px 78px 32px 275px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "user . gender . registration . email . phone";

  .User {
    grid-area: user;
  }

  .Gender {
    grid-area: gender;
  }

  .Registration {
    grid-area: registration;
  }

  .Phone {
    grid-area: phone;
  }

  .Email {
    grid-area: email;
  }
}

.UserItem {
  grid-template-columns: 36px 8px 146px 32px 46px 32px 78px 32px 275px 32px 90px 32px auto;
  grid-template-rows: auto;
  grid-template-areas:
      "logo . user . gender . registration . email . phone . more";

  &:last-child {
    padding-bottom: 0;
  }

  .Logo {
    grid-area: logo;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .User {
    grid-area: user;
  }

  .Gender {
    grid-area: gender;
  }

  .Registration {
    grid-area: registration;
  }

  .Phone {
    grid-area: phone;
  }

  .Email {
    grid-area: email;
  }

  .More {
    grid-area: more;
    justify-self: end;
  }
}

.Banned {
  .Logo {
    opacity: 0.55;
  }

  .User {
    opacity: 0.55;
  }

  .Gender {
    opacity: 0.55;
  }

  .Registration {
    opacity: 0.55;
  }

  .Phone {
    opacity: 0.55;
  }

  .Email {
    opacity: 0.55;
  }
}