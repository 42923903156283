@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.TagsAndInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  .Info {
    min-width: 80px;
    @include text-14-reg;
    color: var(--new);
  }
}

.Title {
  margin-bottom: 12px;
  @include text-32-bold;
  color: var(--text-01);
}

.Amount {
  margin-bottom: 12px;
  @include text-12-reg;
  color: var(--new);
}

.RadioButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .Left {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  .Right {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.OptionsContainer {
  .Option {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .TagsAmount {
      margin-left: 16px;
      margin-right: 12px;
      @include text-12-reg;
      color: var(--text-03);
    }
  }
}

.Tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .Tag {
    @include text-14-reg;
    color: var(--dark-blue);
    cursor: default;
  }
}

.Button {
  position: absolute;
  right: 24px;
  top: 32px;
}

.Icon {
  margin-right: 9px;
}

.TitleContainer {
  margin-bottom: 24px;
}

.SubTitle {
  margin-top: 24px;
  margin-bottom: 8px;
  @include text-14-reg;
  color: var(--text-01);
}

.CountOptionsContainer {
  display: flex;
  align-items: center;
  margin-top: 24px;

  span {
    display: inline-block;
    @include text-14-reg;
    color: var(--text-01);
  }

  .Dropdown {
    max-width: 160px;
    margin-bottom: 0;
    margin-left: 16px;
    margin-right: 24px;
  }
}

.OptionsContainer {
  .Header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include text-14-reg;
    color: var(--text-01);

    .Answer {
      width: 100%;
      max-width: 260px;
      margin-right: 16px;
    }
  }
}

.ModalText {
  margin-top: 16px;
  margin-bottom: 24px;
  @include text-14-reg;
  color: var(--text-01);
}

.ChildrenMessage {
  text-decoration: underline;
  cursor: pointer;
}