.MoreItem{
  padding: 10px 20px 10px 20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #303141;
  &:hover{
    background-color: #F0F1FB;
    cursor: pointer;
  }
}

.Danger{
  color:#FF5722;
}