.Container {
  position: absolute;
  right: 0;
  bottom: 26px;
  min-width: 109px;
  padding: 4px 10px;
  background: #ef3340;
  border-radius: 5px 0px 0px 5px;
}

.Price {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.DiskountAndPercentContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.Discount {
  display: block;
  margin-right: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  text-decoration-line: line-through;
  color: #ffffff;
  opacity: 0.5;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.DiscountPercent {
  display: block;
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.01em;
}
