.Container {
  background: #ffffff;
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;
  margin-bottom: 50px;
}

.Title {
  display: inline-block;
  height: 24px;
  margin-left: 24px;
  margin-top: 32px;
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #303141;
}

.FieldTitle {
  display: block;
  margin-top: 24px;
  margin-left: 24px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #303141;
}

.Field {
  margin-top: 8px;
  margin-left: 24px;
  padding: 14px 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #303141;
  background-color: var(--input-bg-color);
  border: 0;
  border-radius: var(--input-border-radius)
}

.Discount {
  display: block;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 32px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  align-items: center;
  color: #303141;
}
