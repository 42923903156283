@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.FormContainer {
  display: flex;

  .MainContainer {
    display: flex;
    flex-direction: column;
    width: 689px;
    margin-right: 24px;
  }

  .PreviewContainer {
    width: 383px;
  }

  .Dropzone {
    width: 335px;
    height: 419px;
    filter: initial;
  }

  .UserItem {
    margin-top: 24px;
  }

  .LabelMargin {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .Label {
    @include text-14-reg;
    color: var(--text-01);
  }

  .Buttons {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .Left {
      height: 40px;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .Right {
      height: 40px;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .Subtitle {
      margin-right: 40px;
      @include text-14-reg;
      color: var(--text-01);
    }
  }

  .Schedule {
    margin-top: 24px;

    .ScheduleInput {
      color: var(--text-01);
      background-color: var(--input-bg-color);
      border-radius: var(--input-border-radius);

      &::placeholder {
        color: var(--text-03);
      }
    }
  }

  .GalleryContainer {
    margin-top: 24px;

    .Gallery {
      grid-template-columns: repeat(2, 1fr);
      gap: 13px;
    }

    .ItemStyle {
      width: 161px;
      height: 161px;

      &:nth-child(2) {
        grid-column: 1 / span 2;
        width: 100%;
        height: 336px;
      }
    }

    .GalleryDropzoneWithMedia {
      width: 161px;
      height: 161px;
      order: 2;
    }

    .GalleryDropzoneWithoutMedia {
      width: 100%;
      grid-column: 1 / span 2;
    }
  }
}