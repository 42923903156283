@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.CreateOfferSchedule {
  display: block;
  min-height: 260px;
  padding-top: 32px;
  padding-left: 24px;
  background: var(--white);
  box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
  border-radius: 6px;
  padding-bottom: 40px;
  margin-bottom: 50px;
}

.StartEndTimeList {
  list-style: none;
  padding: 0;
  display: inline-flex;
  margin-bottom: 12px;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.StartEndTimeList li {
  margin-right: 20px;
}

.ModeList {
  display: inline-flex;
  padding: 0;
  margin-top: 40px;
  list-style-type: none;

  @include media-breakpoint-down(sm) {
    display: block;
    margin-top: 24px;
  }
}

.DayContainer {
  margin-left: 24px;
}

.Title {
  display: block;
  margin-bottom: 29px;
  height: 24px;
  @include text-20-bold;
  color: var(--text-01);
}
