.Container{
  display: flex;
  align-items: center;

  .Logo{
     img,div{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
  .NameAndInfo{

    .Name{
      font-family: Jost, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #303141;
    }
    .Info{
      display: flex;
      align-items: center;
      font-family: Jost,sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: #303141;

      svg{
        margin-left: 11px;
        margin-right: 5px;
      }
    }
  }
}
