.MessageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 15px rgba(48, 49, 65, 0.05);
    border-radius: 6px;
}

.Message{
    padding: 8px 20px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.Error{
    background-color: #303141;
}

.Success{
    background-color: #37B9AA;
}

.Warning{
    background-color: #EF3340;
}
