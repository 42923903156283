.Container{
  input[type="radio"]{
    display: none;
  }
  input[type="radio"]+span{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: 110px;
    height: 32px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  input[type="radio"]:checked + span{
    background: #3C49C6;
    color: #FFFFFF;
  }

  input[type="radio"]:not(:checked) + span{
    background: #FFFFFF;
    color: #3C49C6;
    border: 1px solid #3C49C6;
  }
}

.Disabled{
  input[type="radio"]:not(:checked) + span{
    background: #FFFFFF;
    color: #C1C1C6;
    border: 1px solid #C1C1C6;
    cursor: not-allowed;
  }
}

.CircleContainer{
  width: fit-content;
  label{
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
    cursor: pointer;
  }

  input[type="radio"]{
    display: none;
  }

  input[type="radio"]+span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 12px;
  }

  input[type="radio"]:checked + span{
    background-color: #37B9AA;
    border: none;
    svg{
      display: block;
    }
  }
  input[type="radio"]:not(:checked) + span{
    background-color: transparent;
    border: 1px solid #C1C1C6;
    svg{
      display: none;
    }
  }
}
