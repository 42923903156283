@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.RequestsContainer {
  border-radius: 0 8px 0 0;

  .Header {
    grid-template-columns: 222px 24px 72px 24px 130px 24px 280px 12px auto;
    grid-template-rows: auto;
    grid-template-areas:
      "Name . Created . RedemtionOption . IBAN . Status";

    .Created {
      grid-area: Created;
    }

    .Name {
      grid-area: Name;
    }

    .RedemtionOption {
      grid-area: RedemtionOption;
    }

    .IBAN {
      grid-area: IBAN;
    }

    .Status {
      grid-area: Status;
    }
  }

  .Item {
    grid-template-columns: 222px 24px 72px 24px 130px 24px 280px 12px auto;
    grid-template-rows: auto;
    grid-template-areas:
      "Name . Created . RedemtionOption . IBAN . Status";

    &:hover {
      background-color: var(--bg);
      cursor: pointer;
    }

    .Name {
      grid-area: Name;
    }

    .Created {
      grid-area: Created;
    }

    .RedemtionOption {
      grid-area: RedemtionOption;
    }

    .IBAN {
      grid-area: IBAN;
    }

    .Status {
      grid-area: Status;
    }

    &:last-child {
      border: none
    }
  }
}

.OptionsContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 32px 24px;
  background: var(--white);
  border-radius: 0 8px 8px 8px;
}

.SearchContainer {
  padding: 8px 0 8px 0;
}