@import "src/submodules/naoo-web-components/styles/variables";
@import "src/submodules/naoo-web-components/styles/mixins";

.Container {
  display: grid;
  align-items: center;
  @include text-14-reg;
  color: var(--new);
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}