.Container{
  width: 240px;

  .Label{
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303141;
    margin-bottom: 8px;
  }
  :global{
    .react-colorful{
      width: 240px ;
      height: 240px;
      .react-colorful__saturation{
        border-radius: 12px;
      }
    }

    .react-colorful__hue{
      height: 16px;
      margin-top: 12px;
      border-radius: 32px;
    }

    .react-colorful__pointer{
      width: 24px;
      height: 24px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    }
  }
}

.Test{
  width: 200px;
  height: 200px;
}

